//スライドショーが1回転するのにかかる時間を設定
//PCは12度ずつアイテムを配置、SPは18度ずつにアイテムを配置するので、一周にかかる時間はSPはPCの12/18 = 2/3にする必要がある
$aroundtime-pc: 120 + 0s; //PC時に、一周するのにかかる時間。単位は秒
$aroundtime-sp: ( $aroundtime-pc * (12 / 18) ) + 0s; //PC時に、一周するのにかかる時間。単位は秒


.p0-slideshow {
  &_item {    
    a {
      display: block;
    }
  }
  
  &.-vegetable {
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 192vw; //( ( ( 690px + 15px + 15px ) * 2) / 750px ) * 100, ((円の半径 + 上余白30px + 下余白30px ) ×2) / 全体幅 * 100(vw化)
    height: 192vw;
    padding: 2vw 0;  // (15px / 750px) * 100
    background-color: color-white01(1);
    border-radius: 50%;

    @include breakpoint-pc {
      width: 190.1234567901235vw; //( ( ( 1480px + 30px + 30px ) * 2) / 1620px ) * 100, ((円の半径 + 上余白30px + 下余白30px ) ×2) / 全体幅 * 100(vw化)
      height: 190.1234567901235vw;
      padding: 1.851851851851852vw 0;  // (30px / 1620px) * 100
    }

    .p0-slideshow {
      &_inner {
        position: relative;
        left: calc( (192vw / 2) - 11.6vw );  //( ( .-vegetable幅 / 2 ) - .p0-slideshow_rotation幅の半分 )
        z-index: 2;
        width: calc(192vw / 2);
        height: calc(192vw / 2);

        @include breakpoint-pc {
          left: calc( (190.1234567901235vw / 2) - 7.4074074074074vw );  //( ( .-vegetable幅 / 2 ) - .p0-slideshow_rotation幅の半分 )
          width: calc(190.1234567901235vw / 2);
          height: calc(190.1234567901235vw / 2);
        }
      }

      &_rotation {
        width: 23.2vw;  // 174px / 750px * 100 ( 丸写真の幅 / 全体幅 * 100(vw化) )
        height: 100%;
        animation: $aroundtime-sp rotation18 3s ease-in-out reverse infinite; //アニメーション時間 keyframes名 遅延時間 進行 方向 回数
        transform-origin: center bottom;

        @include breakpoint-pc {
          width: 14.8148148148148vw;  // 240px / 1620px * 100 ( 丸写真の幅 / 全体幅 * 100(vw化) )
          animation: $aroundtime-pc rotation12 3s ease-in-out reverse infinite; //アニメーション時間 keyframes名 遅延時間 進行 方向 回数
        }
      }
      
      &_item {
        display: none;  //基本的にdisplay: none;にした上で、下で表示する_itemにのみ、for内でdisplay: block;を指定する。
        position: absolute;
        width: 23.2vw;  // 174px / 750px * 100 ( 丸写真の幅 / 全体幅 * 100(vw化) )
//        height: 50vw;
        height: calc(192vw / 2);
//        height: 1480px;
        transform-origin: center bottom;

        //20個分の円形写真を配置(角度を指定)
        @for $value from 1 through 20 {
          &:nth-of-type(#{ $value }) {
            display: block; //スマホ時は、20個目までの画像について、表示する。
            transform: rotate(#{ $value * 18 }deg);
          }
        }
        
        @include breakpoint-pc {
          width: 14.8148148148148vw;  // 240px / 1620px * 100s
          height: calc(190.1234567901235vw / 2);

          //30個分の円形写真を配置(角度を指定)
          @for $value from 1 through 30 {
            &:nth-of-type(#{ $value }) {
              display: block; //PC時は、30個目までの画像について、表示する。
              transform: rotate(#{ $value * 12 }deg);
            }
          }
        }
      }
      
      &_item {
//        display: block;
        
        a, span {
          display: inline-block;
          overflow: hidden;
          border-radius: 100vh;
        }
        
        img {
          overflow: hidden;
          border-radius: 100vh;
          pointer-events: none;
        }
      }
    }
  }
}


/*****************************************
 * 動いている時間 : 止まっている時間 = 1 : 11
 ****************************************/
//アイテム30個配置(12度ずつ配置)
$l12: 100 / 360;  //1度が何%に相当するかを計算(100%の時、360度になる)
$rotationtmp12: 0;
@keyframes rotation12 {
  @for $value from 0 through 360 {
    #{ $value * $l12 }% {
      @if ( $value % 12 ) == 0  {  //12の倍数
        $rotationtmp12: $value !global; //グローバル変数として$rotationtmp12の値を上書き
      }
      transform: rotate($rotationtmp12 * 1 + deg);
    }
  }
}

//アイテム20個配置(18度ずつ配置)
$l18: 100 / 360;
$rotationtmp18: 0;
@keyframes rotation18 {
  @for $value from 0 through 360 {
    #{ $value * $l18 }% {
      @if ( $value % 18 ) == 0  {  //18の倍数
        $rotationtmp18: $value !global; //グローバル変数として$rotationtmp18の値を上書き
      }
      transform: rotate($rotationtmp18 * 1 + deg);
    }
  }
}
