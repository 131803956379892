.p0-slider {
  position: relative;
  width: 100%;
  padding: 0 49px;  // 34px + 15px
  box-sizing: border-box;
  
  @include breakpoint-pc {
    padding: 0 58px;  // 43px + 15px
  }
  
  &_item {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    
    img {
      opacity: 0;
    }
  }
  
  //野菜図鑑 詳細ページスライドショー
  &.-harvest {
    padding: 0 9px;

    @media all and (min-width: 640px) {
      padding: 0 49px;  // 34px + 15px
    }
    @include breakpoint-pc {
      padding: 0 58px;  // 43px + 15px
    }

    .swiper-container {
      max-width: 480px;

      @media all and (min-width: 640px) {
        min-width: 480px;
        max-width: 720px;
      }
    }
  }


  ///// Swiper基本設定 /////
  .swiper-container {
    position: relative;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    
    .swiper-wrapper {
  //    overflow: hidden;
    }
  }
  
  .swiper-button {
    &-prev, &-next {
      width: 18px;  //23px * 0.8
      height: 23px;  //29px * 0.8
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
  
      @include breakpoint-pc {
        width: 23px;
        height: 29px;
      }

      &:hover, &:focus {
        filter: alpha(opacity=70);
        opacity: 0.7;
      }
    }
  
    &-prev {
      left: 0;
      background-image: url("../images/common/icon/slider_prev.png");
    }
  
    &-next {
      right: 0;
      background-image: url("../images/common/icon/slider_next.png");
    }
  }
  
  .swiper-pagination {
    position: relative !important;
    z-index: 10;
  
    &.swiper-pagination-bullets {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-center;
      @include flex-align-items-center;
      bottom: -15px;
      
      @include breakpoint-pc {
        bottom: -30px;
      }
  
      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        margin: 0 3px;
        opacity: 1;
        background: color-gray03(1) !important;
        cursor: pointer;

        @include breakpoint-pc {
          width: 10px;
          height: 10px;
          margin: 0 3px;
        }
        
        &:hover, &:focus {
          filter: alpha(opacity=70);
          opacity: 0.7;
        }
        
        &.swiper-pagination-bullet-active {
          background: color-black01(1) !important;
        }
      }
    }
  }

}
