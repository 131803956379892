//改行
//〜以上になった時に改行する場合
//スマホ以上
@include breakpoint-sp {
  .break.-minsp {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//タブレット以上
@include breakpoint-tb {
  .break.-mintb {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//コンテンツハーフ以上
@include breakpoint-contenthalf {
  .break.-minch {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//520以上
@media all and (min-width: 520px) {
  .break.-min520 {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//PC以上
@include breakpoint-pc {
  .break.-minpc {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//コンテンツ以上
@include breakpoint-content {
  .break.-mincontent {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//1200以上
@media all and (min-width: 1200px) {
  .break.-min1200 {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}


//〜以下になった時に改行する場合
//PC以下
@include max-breakpoint-pc {
  .break.-maxpc {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//メインコンテンツ幅の半分以下
@include max-breakpoint-contenthalf {
  .break.-maxch {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//タブレット以下
@include max-breakpoint-tb {
  .break.-maxtb {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//スマホ以下
@include max-breakpoint-sp {
  .break.-maxsp {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
