.p0-tbl {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  @include breakpoint-pc {
  }
  
  ///// 横並び・縦並び切り替え /////
  &.-vh {
    display: block;
    
    @include breakpoint-pc {
      display: table;
    }
    
    tbody {
      display: block;
      width: 100%;
      
      @include breakpoint-pc {
        display: table-row-group;
      }
    }
    
    tr {
      display: block;
      
      @include breakpoint-pc {
        display: table-row;
      }
      
      &:last-of-type {
        th, td {
          border-bottom-style: none;
        }
      }
    }
    
    th, td {
      display: block;
      width: 100%;
      padding: 10px 0;
      border-bottom: 1px solid color-sub01(1);
      box-sizing: border-box;
      
      @include breakpoint-pc {
        display: table-cell;
        width: auto;
        padding: 20px 0;
      }
    }
    
    th {
      padding-bottom: 0;
      padding-left: 15px;
      padding-right: 15px;
      border-bottom-style: none;
      text-align: left;
      vertical-align: top;
      
      @include breakpoint-sp {
        padding-left: 30px;
        padding-right: 30px;
      }
      @include breakpoint-pc {
        width: 240px;
        min-width: 240px;
        padding-bottom: 20px;
        padding-left: 60px;
        padding-right: 60px;
        border-bottom-style: solid;
        text-align: center;
      }
      
      @include breakpoint-content {
        width: 260px;
        min-width: 260px;
        padding-left: 80px;
        padding-right: 60px;
      }
    }
    
    td {
      padding-left: 15px;
      padding-right: 15px;

      @include breakpoint-sp {
        padding-left: 30px;
        padding-right: 30px;
      }
      @include breakpoint-pc {
        padding-left: 0;
        padding-right: 60px;
      }
    }
  }


  ///// 等分割テーブル /////
  &.-equaldiv {
    border: 1px solid color-sub06(1);

    th, td {
      box-sizing: border-box;
      border-right: 1px solid color-sub06(1);
    }
    
    th {
      padding: 5px 10px 7px;
      color: color-black01(1);
      background-color: color-red01(1);
    }

    td {
      padding: 10px 10px 12px;
    }
  }

  ///// 3分割 /////
  &.-col3 {
    th, td {
      width: 33.333%;
      width: calc( 100% / 3 );
    }
  }

  ///// 4分割 /////
  &.-col4 {
    th, td {
      width: 25%;
    }
  }

  ///// スクロールテーブル(表の最小横幅を指定する) /////
  ///// 料金テーブル /////
  &.-pricetbl {
    min-width: 580px;
  }

}