.l-wrap {
  overflow: hidden;
}


.l-section {
  width: 100%;
  padding: 20px 0;
  position: relative;
  overflow: hidden;

  @include breakpoint-pc {
    padding: 60px 0;
  }

  &.-visible {
    overflow: visible;
  }

  &.-pad0 {
    padding: 0;
  }

  &.-pt0 {
    padding-top: 0;
  }

  &.-halfSpace {
    padding: 15px 0;

    @include breakpoint-pc {
      padding: 30px 0;
    }

    &.-pb0 {
      padding: 15px 0 0;

      @include breakpoint-pc {
        padding: 30px 0 0;
      }
    }
  }

  &.-largeSpace {
    padding: 40px 0;
    @include breakpoint-pc {
      padding: 80px 0;
    }

    &.-pb0 {
      padding: 40px 0 0;
      @include breakpoint-pc {
        padding: 80px 0 0;
      }
    }
  }

  &.-doubleSpace {
    padding: 60px 0;

    @include breakpoint-pc {
      padding: 120px 0;
    }
  }

  &.-pt40 {
    padding: 20px 0 20px;

    @include breakpoint-pc {
      padding: 40px 0 60px;
    }
  }

  &.-pt50 {
    padding: 25px 0 25px;

    @include breakpoint-pc {
      padding: 50px 0 50px;
    }
  }

  &.-pt60 {
    padding: 30px 0 30px;

    @include breakpoint-pc {
      padding: 60px 0 60px;
    }
  }

  &.-pt120 {
    padding: 60px 0 30px;

    @include breakpoint-pc {
      padding: 120px 0 60px;
    }
  }
  
  
  ///// z-index /////
  &.-zi1 {
    z-index: 1;
  }


  ///// セクション全体の背景色を変える /////
  &.-bgtype01 {
    margin-top: -1px; //境目の線を見えなくするようにするために、1px上にずらす
    background-color: color-wh(1);
  }
  &.-bgtype02 {
    margin-top: -1px; //境目の線を見えなくするようにするために、1px上にずらす
    background-color: color-gray01(1);
  }
  &.-bgtype03 {
    margin-top: -1px; //境目の線を見えなくするようにするために、1px上にずらす
    background-color: color-white01(1);
    background-image: url("../images/common/bg/pattern01.png");
    background-position: center top;
    background-repeat: repeat;
    background-size: 128px auto;
  }
  &.-bgtype04 {
    background-color: transparent;
  }
  &.-bgtype05 {
    background-color: color-white01(1);
  }
  &.-bgtype06 {
    background-color: color-yellow01(1);
  }

  &_content {
    position: relative;
    overflow: hidden;
    width: 90vw;
    margin: 0 auto;

    @media all and (min-width: 1360px) {  //1240px + 60px + 60px
      width: $mainboxinner;
    }

    &.-visible {
      overflow: visible;
    }
    
    //最大1350px
    &_innerwide {  
      width: 90vw;
      margin: 0 auto;
      position: relative;

      @media all and (min-width: 1470px) {  //1350px + 60px + 60px
        width: $wideinner;
      }
    }

    //最大1120px
    &_innerpad {  
      width: 100%;
      margin: 0 auto;
      position: relative;

      @include breakpoint-content {
        width: 90vw;
      }
      @media all and (min-width: $mainboxinner) {  //1120px + 60px + 60px
        width: $content;
      }
    }

    //最大1120px(最大90%)
    &_innercontent {  
      width: 90%;
      margin: 0 auto;
      position: relative;

      @media all and (min-width: $mainboxinner) {  //1120px + 60px + 60px
        width: $content;
      }
    }

    //最大1000px
    &_inner {
      width: 100%;
      margin: 0 auto;
      position: relative;

      @include breakpoint-pc {
        width: 90vw;
      }
      @media all and (min-width: 1240px) {  //1120px + 60px + 60px
        width: $contentinner;
      }
    }
  }

  &.-last {
    overflow: hidden;
    padding-top: 60px;
    padding-bottom: 60px;

    @include breakpoint-pc {
      padding-top: 120px;
      padding-bottom: 120px;
    }
  }
}



///// 上端位置調整(マイナス指定) /////
.l-ajust {
  &.-minus0_40 {
    margin-top: -40px;

    @include breakpoint-pc {
      margin-top: 0;
    }
  }

  &.-minus100_0 {
    margin-top: 0;

    @include breakpoint-pc {
      margin-top: -100px;
    }
  }

  &.-minus130_0 {
    margin-top: 0;

    @include breakpoint-pc {
      margin-top: -130px;
    }
  }
}
