.p2-copyright {
  @include font-ntsan("r");
  color: color-wh(1);
  font-size: 1rem;
  text-align: center;
  line-height: 1;

  @include breakpoint-footerswitch {
    text-align: right;
  }
  @include breakpoint-pc {
    font-size: 1.2rem;
  }
  @include breakpoint-mainboxinner {
    font-size: 1.3rem;
  }
}