$max: 1620px; // 最大幅 - 1620px
$mainbox: 1500px; // メインボックス - 1500px
$wideinner: 1350px; // 幅広内側幅 - 1350px
$mainboxinner: 1240px; // メインボックス内側幅 - 1240px
$content: 1120px; // メインコンテンツ幅 - 1120px(1240px - 60px - 60px)
$contentinner: 1000px; // メインコンテンツ内側幅 - 1000px(1120px - 60px - 60px)
$pc: 800px; // PC - 800
$contenthalf: 620px; // メインコンテンツ幅の半分 - 620px
$footerswitch: 550px; // フッターの表示切り替え - 560px
$contentinnerhalf: 500px; // メインコンテンツ内側幅の半分 - 500px
$tb: 480px; // タブレット - 480
$sp: 375px; // スマホ - 375

@mixin max-breakpoint-pc {
  @media all and (max-width: calc(#{$pc} - 1px)) {
    @content;
  }
}
@mixin max-breakpoint-contenthalf {
  @media all and (max-width: calc(#{$contenthalf} - 1px)) {
    @content;
  }
}
@mixin max-breakpoint-tb {
  @media all and (max-width: calc(#{$tb} - 1px)) {
    @content;
  }
}
@mixin max-breakpoint-sp {
  @media all and (max-width: calc(#{$sp} - 1px)) {
    @content;
  }
}

@mixin breakpoint-max {
  @media all and (min-width: ($max)) {
    @content;
  }
}
@mixin breakpoint-mainbox {
  @media all and (min-width: ($mainbox)) {
    @content;
  }
}
@mixin breakpoint-wideinner {
  @media all and (min-width: ($wideinner)) {
    @content;
  }
}
@mixin breakpoint-mainboxinner {
  @media all and (min-width: ($mainboxinner)) {
    @content;
  }
}
@mixin breakpoint-content {
  @media all and (min-width: ($content)) {
    @content;
  }
}
@mixin breakpoint-contentinner {
  @media all and (min-width: ($contentinner)) {
    @content;
  }
}
@mixin breakpoint-pc {
  @media all and (min-width: ($pc)) {
    @content;
  }
}
@mixin breakpoint-contenthalf {
  @media all and (min-width: ($contenthalf)) {
    @content;
  }
}
@mixin breakpoint-footerswitch {
  @media all and (min-width: ($footerswitch)) {
    @content;
  }
}
@mixin breakpoint-contentinnerhalf {
  @media all and (min-width: ($contentinnerhalf)) {
    @content;
  }
}
@mixin breakpoint-tb {
  @media all and (min-width: ($tb)) {
    @content;
  }
}
@mixin breakpoint-sp {
  @media all and (min-width: ($sp)) {
    @content;
  }
}


////// display switch //////
.disp {
  &-block {
    //1000pxで切り替わる
    &-contentinner {
      display: none;
      
      @include breakpoint-contentinner {
        display: block;
      }
    }
  
    &-sppc {
      display: block;
      
      @include breakpoint-contentinner {
        display: none;
      }
    }

    //800pxで切り替わる
    &-pc {
      display: none;
      
      @include breakpoint-pc {
        display: block;
      }
    }
  
    &-sptb {
      display: block;
      
      @include breakpoint-pc {
        display: none;
      }
    }
  
    //620pxで切り替わる
    &-pccontenthalf {
      display: none;
      
      @include breakpoint-contenthalf {
        display: block;
      }
    }
  
    &-spcontenthalf {
      display: block;
      
      @include breakpoint-contenthalf {
        display: none;
      }
    }

    //480pxで切り替わる
    &-pctb {
      display: none;
      
      @include breakpoint-tb {
        display: block;
      }
    }
  
    &-sp {
      display: block;
      
      @include breakpoint-tb {
        display: none;
      }
    }
  
    //375pxで切り替わる
    &-pcsp {
      display: none;
      
      @include breakpoint-sp {
        display: block;
      }
    }
  
    &-sponly {
      display: block;
      
      @include breakpoint-sp {
        display: none;
      }
    }
  }

  &-inline {
    //1000pxで切り替わる
    &-contentinner {
      display: none;
      
      @include breakpoint-contentinner {
        display: inline;
      }
    }
  
    &-sppc {
      display: inline;
      
      @include breakpoint-contentinner {
        display: none;
      }
    }

    //800pxで切り替わる
    &-pc {
      display: none;
      
      @include breakpoint-pc {
        display: inline;
      }
    }
  
    &-sptb {
      display: inline;
      
      @include breakpoint-pc {
        display: none;
      }
    }
  
    //620pxで切り替わる
    &-pccontenthalf {
      display: none;
      
      @include breakpoint-contenthalf {
        display: inline;
      }
    }
  
    &-spcontenthalf {
      display: inline;
      
      @include breakpoint-contenthalf {
        display: none;
      }
    }

    //480pxで切り替わる
    &-pctb {
      display: none;
      
      @include breakpoint-tb {
        display: inline;
      }
    }
  
    &-sp {
      display: inline;
      
      @include breakpoint-tb {
        display: none;
      }
    }
  
    //375pxで切り替わる
    &-pcsp {
      display: none;
      
      @include breakpoint-sp {
        display: inline;
      }
    }
  
    &-sponly {
      display: inline;
      
      @include breakpoint-sp {
        display: none;
      }
    }
  }
}