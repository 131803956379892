.p0-harvest {
  ///// 野菜図鑑カテゴリ /////  
  $harvest-tags-item-gap-x-pc: 6px;
  $harvest-tags-item-gap-x-sp: 6px;
  
  &_tags {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include flex-align-items-center;
    gap: 0 $harvest-tags-item-gap-x-sp;
    @include font-zmgo("m");
    @include fontSize(1.6, 1.4);

    @include breakpoint-pc {
      gap: 0 $harvest-tags-item-gap-x-pc;
    }
    
    &_item {
      display: block;
      margin-bottom: 8px;
      padding: 5px 13px 6px;
      border: 2px solid transparent;
      border-radius: 100vh;
      box-sizing: border-box;
      color: color-black01(1);

      &.-cat {
        color: color-wh(1);

        &.-normal {
          background-color: color-green04(1);
          border-color: color-green04(1);
          
          &.-current {
            color: color-green04(1);
            background-color: color-wh(1);
          }
        }
  
        &.-western-colorful {
          background-color: color-orange01(1);
          border-color: color-orange01(1);
          
          &.-current {
            color: color-orange01(1);
            background-color: color-wh(1);
          }
        }
  
        &.-herb {
          background-color: color-red03(1);
          border-color: color-red03(1);
          
          &.-current {
            color: color-red03(1);
            background-color: color-wh(1);
          }
        }
        
        &.-group {
          border-color: color-red03(1);
        }
      }
      
      &.-name {
        border-color: color-brown01(1);
      }
    }
  }


  ///// 野菜図鑑一覧 /////
  $harvest-list-item-gap-x-pc: 50px;
  $harvest-list-item-gap-x-sp: 35px;
  $harvest-list-item-gap-y-pc: 70px;
  $harvest-list-item-gap-y-sp: 25px;

  &_list {
    display: grid;
    justify-content: start;
    grid-template-columns: 1fr 1fr;
    grid-gap: $harvest-list-item-gap-y-sp $harvest-list-item-gap-x-sp;

    @include breakpoint-tb {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include breakpoint-pc {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: $harvest-list-item-gap-y-pc $harvest-list-item-gap-x-pc;
    }
  }

  &_relatedlist {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include flex-align-items-stretch;
    gap: $harvest-list-item-gap-y-sp $harvest-list-item-gap-x-sp;
    @include font-zmgo("m");
    @include fontSize(1.6, 1.4);

    @include breakpoint-pc {
      gap: $harvest-list-item-gap-y-pc $harvest-list-item-gap-x-pc;
    }
  }

  &_list, &_relatedlist {
    &_item {
      display: block;
      
      &_imgbox {
        overflow: hidden;
        border-radius: 50%;
        
        img {
          display: block;
        }
      }
      
      &_txtbox {
        padding-top: 12px;
        box-sizing: border-box;
        
        @include breakpoint-pc {
          padding-top: 24px;
        }
      }
    }
  }

  &_relatedlist {
    &_item {
      width: calc( 50% - #{$harvest-list-item-gap-x-pc / 2} );

      @include breakpoint-tb {
        width: calc( 100% / 3 - #{$harvest-list-item-gap-x-pc * 2 / 3} );
      }
      @include breakpoint-pc {
        width: calc( 25% - #{$harvest-list-item-gap-x-pc * 3 / 4} );
      }
    }
  }

  ///// 野菜図鑑詳細 /////
  &_article {
    &_txt {
      p {
        margin-bottom: 20px;

        @include breakpoint-pc {
          margin-bottom: 35px;
        }
      }
    }
  }

  &_ttlicon {
    text-align: center;

    &.-harvesttime, &.-effect, &.-memo, &.-recipe {
      img {
        width: auto;
        height: 20px;

        @include breakpoint-pc {
          height: 30px;
        }
      }
    }

    &.-feature {
      img {
        width: auto;
        height: 16px;

        @include breakpoint-pc {
          height: 24px;
        }
      }
    }

    &.-nakama {
      img {
        width: auto;
        height: 25px;

        @include breakpoint-pc {
          height: 37px;
        }
      }
    }
  }

  ///// 収穫時期 /////
  $harvest-ht-item-gap-x-pc: 10px;
  $harvest-ht-item-gap-x-sp: 8px;
  $harvest-ht-item-gap-y-pc: 10px;
  $harvest-ht-item-gap-y-sp: 8px;
  $harvest-ht-item-width-pc: 60px;
  $harvest-ht-item-width-sp: 52px;

  &_time {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(4, $harvest-ht-item-width-sp);
    grid-gap: $harvest-ht-item-gap-y-sp $harvest-ht-item-gap-x-sp;
    @include font-zmgo("bd");
    @include fontSize(2.6, 2.275);

    @include breakpoint-tb {
      grid-template-columns: repeat(6, $harvest-ht-item-width-pc);
    }
    @include breakpoint-pc {
      grid-gap: $harvest-ht-item-gap-y-pc $harvest-ht-item-gap-x-pc;
    }
    @include breakpoint-contentinner {
      grid-template-columns: repeat(12, $harvest-ht-item-width-pc);
    }

/*
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include flex-align-items-center;
    gap: 0 $harvest-ht-item-gap-x-sp;
    @include font-zmgo("bd");
    @include fontSize(2.6, 2.275);

    @include breakpoint-pc {
      gap: 0 $harvest-ht-item-gap-x-pc;
    }
*/
    &_item {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-center;
      @include flex-align-items-center;
      width: $harvest-ht-item-width-sp;
      height: $harvest-ht-item-width-sp;
      border: 4px solid transparent;
      border-radius: 50%;
      box-sizing: border-box;
      opacity: 0.25;
      
      @include breakpoint-pc {
        width: $harvest-ht-item-width-pc;
        height: $harvest-ht-item-width-pc;
      }

      &.-active {
        opacity: 1;
      }
      
      &.-m1, &.-m2, &.-m12 {
        border-color: color-brown01(1);
      }
      &.-m3, &.-m4, &.-m5 {
        border-color: color-purple01(1);
      }
      &.-m6, &.-m7, &.-m8 {
        border-color: color-green04(1);
      }
      &.-m9, &.-m10, &.-m11 {
        border-color: color-orange01(1);
      }
      
      p {
        @include flexbox;
        @include flex-wrap-nowrap;
        @include flex-direction-row;
        @include flex-justify-center;
        @include flex-align-items-end;
      }

      small {
        font-size: 62.5%;
      }
    }
  }
  
  &_nakama {
    margin-bottom: 40px;
    
    @include breakpoint-pc {
      margin-bottom: 80px;
    }
    
    &:last-of-type {
      margin-bottom: 0;
    }
  }


  ///// 野菜ページの「この野菜を使ったレシピ」ボックス /////
  &_recipelist {
    &_item {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-row;
      @include flex-justify-space-between;
      @include flex-align-items-stretch;
      border-bottom: 1px solid color-red05(1);
      box-sizing: border-box;
      
      &_imgbox, &_txtbox {
        position: relative;
        width: 100%;
        padding: 5vw 0 0 0;
        box-sizing: border-box;
        
        @include breakpoint-pc {
          width: 50%;
          padding: 3.225806451612903vw;  //40px / 1240px * 100
        }
        @include breakpoint-mainboxinner {
          padding: 40px;
        }
      }
      
      &_imgbox {
        &_inner {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
      
      &_txtbox {
        padding-right: 5vw;
        padding-bottom: 5vw;
        
        @include breakpoint-pc {
          padding-right: 3.225806451612903vw;  //40px / 1240px * 100
          padding-bottom: 3.225806451612903vw;  //40px / 1240px * 100
        }
        @include breakpoint-mainboxinner {
          padding-right: 40px;
          padding-bottom: 40px;
        }

        &:after {
          display: block;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 26px;
          height: 12px;
          content: "";
          background-image: url("../images/common/icon/arrow_blink.gif");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
}