.p0-decoration {
  display: block;
    
  &.-pc {
    display: none !important;
    
    @include breakpoint-pc {
      display: block !important;
    }
  }

  &.-sptb {
    display: block !important;
    
    @include breakpoint-pc {
      display: none !important;
    }
  }

  &.-pctb {
    display: none !important;
    
    @include breakpoint-tb {
      display: block !important;
    }
  }

  &.-sp {
    display: block !important;
    
    @include breakpoint-tb {
      display: none !important;
    }
  }

  & img {
    display: block;
    width: 100%;
    height: auto;
  }
  
  ///// 区切り線 /////
  /////// 左右に丸有 /////
  &.-separator01 {
    display: block;
    position: relative;
    width: 100%;
    height: 1px;
    margin: 0 auto;
    
    &:before, &:after {
      display: inline-block;
      position: absolute;
      top: 50%;
      width: 9px;
      height: 9px;
      content: "";
      border-radius: 50%;
    }
    &:before {
      left: 0;
      transform: translate(-50%, -50%);
    }
    &:after {
      right: 0;
      transform: translate(50%, -50%);
    }
    
    &.-w240 {
      width: 240px;
    }
    
    &.-w480 {
      width: 480px;
      max-width: calc(100% - 4.5px - 4.5px);
    }

    &.-black01 {
      background-color: color-black01(1);
      
      &:before, &:after {
        background-color: color-black01(1);
      }
    }

    &.-purple02 {
      background-color: color-purple02(1);
      
      &:before, &:after {
        background-color: color-purple02(1);
      }
    }

    &.-brown01 {
      background-color: color-brown01(1);
      
      &:before, &:after {
        background-color: color-brown01(1);
      }
    }
  }
  
  
  ///// トップページここから ////
  ///// 農機具 ////
  &.-equipment01 {
    display: block;
    position: absolute;
    top: 0;
    right: -15px;
    transform: translate(100%, -15px);
    width: 49px;
    height: 41px;
    content: "";

    @include breakpoint-pc {
      top: -30px;
      right: -20px;
      transform: rotate(-90deg);
      transform-origin: top right;
      width: 98px; //122px * 0.8
      height: 83px; //103px * 0.8
    }
    @include breakpoint-contentinner {
      width: 110px; //122px * 0.9
      height: 93px; //103px * 0.9
    }
    @include breakpoint-content {
      width: 122px;
      height: 103px;
    }
  }
  
  ///// キャラクター ////
  &.-character01 {
    display: block;
    position: absolute;
    
    &.-pos1 {
      display: none;

      @include breakpoint-pc {
        display: block;
        top: 0;
        right: 2.6vw;
        transform: translate(0, -100%);
        width: 124px; //155px * 0.8
        height: 155px; //193px * 0.8
      }
      @include breakpoint-contentinner {
        width: 140px; //155px * 0.9
        height: 174px; //193px * 0.9
      }
      @include breakpoint-content {
        width: 155px;
        height: 193px;
      }
      @include breakpoint-mainboxinner {
        right: 65px;
      }
    }
    
    &.-pos2 {
      bottom: -111px;
      right: 8px;
      width: 80px;
      height: 100px;

      @include breakpoint-pc {
        display: none;
      }
    }
    
    &.-pos3 {
      top: 0;
      left: 0;
      width: 52px;
      height: 65px;
      transform: translate(0, -100%) scale(-1, 1);

      @include breakpoint-pc {
        display: none;
      }
    }
  }
  
  ///// アウトライン内各種野菜 /////
  &.-cabbegeBlueberry {
    display: block;
    position: absolute;
    left: 8vw;
    bottom: 45px;
    width: 107px;

    @include breakpoint-tb {
      bottom: 0;
      left: auto;
      right: 8.3%;
      transform: translate(0, 43%);
      width: 99px;
    }
    @include breakpoint-pc {
      position: absolute;
      bottom: 0;
      right: -2.6785714285714vw;
      transform: translate(100%, 0);
      width: 15vw; //168px / 1120px
      width: 168px;
    }
    @include breakpoint-content {
      right: -30px;
      width: 168px;
    }
  }

  &.-carrots {
    display: block;
    position: absolute;
    width: 122px;
    bottom: 50px;
    right: 5vw;

    @include breakpoint-tb {
      bottom: 0;
      right: auto;
      left: 55px;
      transform: translate(0, 50%);
      width: 111px;
    }
    @include breakpoint-pc {
      position: relative;
      left: auto;
      bottom: auto;
      right: auto;
      transform: translate(0, 0);
      width: 71.4%; //243px / 340px
      margin: -155px auto 70px 9%;
    }
  }

  &.-turnip {
    display: block;
    position: absolute;
    top: calc( 50% + 5px );
    left: 5vw;
    transform: translate(0, -50%);
    width: 103px;

    @include breakpoint-tb {
      width: 96px;
      top: auto;
      bottom: 0;
      left: 12.3%;
      transform: translate(0, 75%);
    }
    @include breakpoint-contenthalf {
      transform: translate(0, 55.5%);
    }
    @include breakpoint-pc {
      width: 18.3928571428571vw; //206px / 1120px
      top: auto;
      left: auto;
      bottom: -4.4642857142857vw; //50px / 1120px
      right: 0;
      transform: translate(100%, 0);
    }
    @include breakpoint-content {
      width: 206px;
      bottom: -50px;
    }
  }


  ///// 木々 ////
  &.-tree {
    display: block;
    position: absolute;
    bottom: -111px;
    left: -20px;
    width: 156px;
    height: 73px;
    content: "";

    @include breakpoint-pc {
      bottom: -121px;
      left: 0;
      width: 249px; //311px * 0.8
      height: 116px; //145px * 0.8
    }
    @include breakpoint-contentinner {
      width: 280px; //311px * 0.9
      height: 131px; //145px * 0.9
    }
    @include breakpoint-content {
      width: 311px;
      height: 145px;
    }
  }
  ///// トップページここまで ////

  ///// 野菜図鑑 /////
  ///// 波型区切り線 /////
  &.-separatewave {
    width: 100%;
    height: 6px;
    overflow: hidden;

    @include breakpoint-tb {
      height: 10px;
    }

    img {
      width: auto !important;
      height: 6px !important;

      @include breakpoint-tb {
        height: 10px !important;
      }
    }
  }
}