// fadein effect
/* 画面外にいる状態 */
.js-fadeIn {
  opacity : 0.1;
  transform : translate(0, 30px);
  transition : all 1000ms;
}
 
/* 画面内に入った状態 */
.js-fadeIn.js-scrollIn {
  opacity : 1;
  transform : translate(0, 0);
}


//// ドロワーイン・アウト /////
@keyframes drawerIn {
  0% {
    transform: translateY(100vh);
    background: color-black01(0);
  }
  1% {
    background: color-black01(0.95);
  }
  100% {
    background: color-black01(0.95);
    transform: translateY(0);
  }
}
@keyframes drawerOut {
  0% {
    transform: translateY(0);
    background: color-black01(0.95);
  }
  99% {
    background: color-black01(0.95);
  }
  100% {
    background: color-black01(0);
    transform: translateY(100vh);
  }
}


//// フェードイン・アウト /////
@keyframes fadeIn {
  0% {
    opacity : 0;
  }
  100% {
    opacity : 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity : 1;
  }
  100% {
    opacity : 0;
  }
}