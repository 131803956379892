.modal {
  width: 90%;
  max-width: 90% !important;
  height: auto;
  max-height: 90% !important;
  padding: 0 !important;
  box-sizing: border-box;
  background-color: transparent !important;

  //動画モーダルウィンドウ
  &.-movie {
    .modal-inner {
      position: relative;
      width: 100%;
      height: 0; //動画の縦横比を保ってレスポンシブ対応するため、heightは0にする。高さはpadding-topで確保する。
      padding-top: 56.25%; // 高さを指定(16:9)

      // YouTube埋め込み用のiframe
      iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
      }
    }
  }
  
  //画像モーダルウィンドウ
  &.-img {
    display: block;
    margin-bottom: 0;
    
    img {
      position: relative;
      z-index: 1;
      width: auto;
      height: auto;
//      width: 100%;
//      height: auto;
      max-width: 100%;
      max-height: 100%;
      object-fit: scale-down;
      pointer-events: auto;
    }
  }
  
  //開くボタン(トリガー)
  &-open-btn {
    cursor: pointer;
  }
  
  //閉じるボタン
  .remodal-close {
    position: absolute;
    top: -10px;
    left: auto;
    right: 0;
    transform: translateY(-100%);
    width: 28px;
    height: 24px;
    background-image: url("../images/common/icon/modal_close.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    
    &:before {
      display: none;
    }

    &:focus {
      outline:0;
    }
  }
}


//モーダルオープン時の、モーダルの上下左右余白
.remodal-wrapper {
  max-height: 100%;
  padding: 30px 0 !important;
  
  @include breakpoint-max {
    padding: 30px 0 !important;
  }
}



//モーダルオープン時の、モーダル以外の全面背景色設定
.remodal-overlay {
  background-color: rgba(0, 0, 0, 0.4) !important;
}


///// 独自モーダル /////
.c-modal {
  &_bg {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10001;
    width: 100%;
    max-width: 100% !important;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    
    &_inner {
      display: inline-block;
      position: relative;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
      padding: 5%;
      box-sizing: border-box;
      
      &::after {
        display: block;
        position: absolute;
        top: 10px;
        left: auto;
        right: 10px;
        width: 28px;
        height: 24px;
        content: "";
        background-image: url("../images/common/icon/modal_close.png");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
    
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      max-width: 90%;
      max-height: 90%;
      height: 90%;
      object-fit: contain;
    }
  }
  
  &_trigger {
    cursor: pointer;
  }
}