.p1-drawer {
//  display: block;
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-column;
  @include flex-justify-flex-start;
  @include flex-align-items-stretch;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: calc(100dvh + 1px);
  height: 100vh;
  overflow: hidden;
  transform: translateY(100vh);

  @include breakpoint-pc {
    @include flex-direction-row;
    @include flex-justify-space-between;
    position: relative;
    bottom: auto;
    top: 0;
    height: auto;
//    height: 72px;
    transform: translateY(0) !important;
  }
  @include breakpoint-content {
//    height: 80px;
  }
  
  &.is-open {
    @include max-breakpoint-pc {
      animation: 0.6s ease-out 0s 1 both drawerIn;
    }
//    background: color-white01(0.95);
//    transform: translateY(0);
//    transition: all linear;
  }
  
  &.is-close {
    &.is-clickClose {
      @include max-breakpoint-pc {
        animation: 0.4s ease-in 0s 1 both drawerOut;
      }
    }
  }

  &-open {/* ハンバーガーアイコンの設置スペース */
    @include flexbox;
    @include flex-justify-center;
    @include flex-align-items-center;
    position: absolute;
    top: 50%;
    right: 5vw;
    z-index: 1200;/* 重なり順を一番上に */
    transform: translateY(-50%);
    width: 87px;
    height: 30px;
    box-sizing: border-box;
    cursor: pointer;
//    background-color: color-white02(1);

    @include breakpoint-pc {
      display: none;
    }

    /* ハンバーガーメニューのアイコン */
    & span, & span:before, & span:after {
      display: block;
      position: absolute;
      right: 0;
      height: 2px;
      width: 30px;
      content: '';
      border-radius: 100vh;
      background-color: color-white02(1);
      transition: 0.5s;
    }

    & span {
      &::before {
        bottom: 10px;
//        bottom: 13px;
        transform-origin: top left;
      }
      &::after {
        top: 10px;
//        top: 13px;
        transform-origin: bottom left;
      }
    }
    
    & p {
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      @include font-lato("b");
      font-size: 1.5rem;
      color: color-wh(1);
    }
    
    //オープン時(×ボタン)
    &.is-open {
      span {
        background-color: color-white02(0);
      }
      
      span::before {
        transform: translate(5px, 0) rotate(45deg);
//        transform: translate(5px, 3px) rotate(45deg);
      }
      span::after {
        transform: translate(5px, 1px) rotate(-45deg);
//        transform: translate(5px, -2px) rotate(-45deg);
      }
    }
  }

  //ドロワー内ロゴ
  &_logo {
    //テキストロゴ(SVG)
    &.-txt {
      display: none;
      box-sizing: border-box;
  
      @include breakpoint-pc {
        display: block;
        flex: 1;
        width: 100%;
        padding: 0 15px;
        border-bottom: 6px solid color-black01(1);
        box-sizing: border-box;
      }
      @include breakpoint-contentinner {
        padding: 0 20px;
      }
      @include breakpoint-content {
        padding: 0 40px;
      }
    }
    
    //画像ロゴ
    &.-img {
      display: block;
      
      @include breakpoint-pc {
        display: none;
      }
    }
  }
}

