.p0-instagram {
  padding-top: 20px;
  padding-bottom: 0;
  border-top: 1px solid color-black01(1);
  border-bottom: 1px solid color-black01(1);
  box-sizing: border-box;

  @include breakpoint-pc {
    padding-top: 50px;
    padding-bottom: 20px;
  }
  
  #sb_instagram.sbi_col_3 #sbi_images {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-justify-space-between;
    @include flex-align-items-stretch;
  }

  #sb_instagram.sbi_col_3 #sbi_images .sbi_item {
    width: calc(50% - 10px);
    padding-bottom: 20px !important;
//    padding: 0 10px 20px 10px !important;
    box-sizing: border-box;

//    @include breakpoint-tb {
//      width: calc(50% - 10px);
//      width: 46.875%;
//    }
    
    @media all and (min-width: 420px) {
      width: calc((100% / 3) - 10px);
    }
  
    @include breakpoint-pc {
      width: calc((100% / 3) - 20px);
      padding-bottom: 30px !important;
//      padding: 0 15px 30px 15px !important;
    }
  }
}