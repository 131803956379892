.p2-ftinfo {
  @include font-ntsan("r");
  font-size: 1.2rem;
  color: color-wh(1);
  line-height: 1;
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-column;
  @include flex-justify-flex-start;
  @include flex-align-items-start;

  @include breakpoint-tb {
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    font-size: 1.3rem;
  }

  &_logobox {
    width: 100%;
    margin: 0 auto 10px;
    text-align: center;

    @include breakpoint-tb {
      @include flex-shrink-0;
      width: 88px; //110px * 0.9
//      width: 77px; //110px * 0.7
      margin: 0;
      text-align: left;
    }
    @include breakpoint-contenthalf {
      width: 88px; //110px * 0.9
    }
    @include breakpoint-pc {
      width: 99px; //110px * 0.9
    }
    @include breakpoint-content {
      width: 110px;
    }
    
    img {
      width: 100%;
      max-width: 100px;
      height: auto;

      @include breakpoint-tb {
        max-width: 110px;
      }
    }
  }

  &_txtbox {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-column;
    @include flex-justify-space-between;
    @include flex-align-items-stretch;
    width: 100%;
  
    @include breakpoint-tb {
      @include flex-wrap-nowrap;
      @include flex-direction-row;
    }
    
    @include breakpoint-tb {
      width: calc( 100% - 88px);
//      width: calc( 100% - 77px);
    }
    @include breakpoint-contenthalf {
      width: calc( 100% - 88px);
    }
    @include breakpoint-pc {
      width: calc( 100% - 99px);
    }
    @include breakpoint-content {
      width: calc( 100% - 110px);
    }
    
    &_item {
      &.-item1 {
        width: 100%;
        margin-bottom: 30px;
        padding: 0 20px;
  
        @include breakpoint-tb {
          width: calc( 100% - 100px );
          margin-bottom: 0;
          padding: 0 0 0 20px;
        }
        @include breakpoint-footerswitch {
          width: calc( 100% - 190px );
          padding: 0 0 0 30px;
        }
        @include breakpoint-pc {
          width: calc( 100% - 250px );
          padding: 0 40px;
        }
        @include breakpoint-content {
          padding: 0 50px;
        }
      }

      &.-item2 {
        @include flexbox;
        @include flex-wrap-wrap;
        @include flex-direction-column;
        @include flex-justify-space-between;
        @include flex-align-items-stretch;
        width: 100%;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
  //      padding-top: 20px;
        box-sizing: border-box;
  
        @include breakpoint-tb {
          @include flex-shrink-0;
          width: 136px;
          margin-right: 0;
          margin-bottom: 0;
          padding-top: 5px;
        }
        @include breakpoint-footerswitch {
          width: 190px;
        }
        @include breakpoint-pc {
          width: 250px;
          padding-top: 0;
        }
      }
    }      

  }


  &_txt {
    &.-name {
      box-sizing: border-box;
      @include font-ntsan("b");
      text-align: center;
      
      @include breakpoint-tb {
        padding-top: 10px;
        text-align: left;
      }

      & .-name_en {
        font-size: 1.4rem;

        @include breakpoint-tb {
          @include fontSize(2.8, 1.9);
        }
      }
      & .-name_jp {
        font-size: 1rem;

        @include breakpoint-tb {
          @include fontSize(1.6, 1.3);
        }
      }
    }
        
    &.-address {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-justify-center;
      @include flex-align-items-center;
      text-align: center;
      
      @include breakpoint-tb {
        @include flex-justify-flex-start;
        text-align: left;
      }

      & .icon {
        transform: translateY(1px);
      }
      
      & span {
        padding-left: 5px;
        box-sizing: border-box;
      }
    }

    &.-link {
      a {
        display: inline-block;
        color: color-wh(1);
        text-decoration: underline;
      }
    }
  }
  
  &_sns {
    @include flexbox;
    @include flex-direction-row;
    @include flex-justify-center;
    @include flex-align-items-center;
    text-align: center;

    @include breakpoint-tb {
      @include flex-justify-flex-end;
      text-align: right;
    }

    & .-fb, & .-ig, & .-tw {
      display: inline-block;
      margin: 0 10px;

      @include breakpoint-tb {
        margin: 0;
        text-align: right;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    & .-fb {
      width: 18px;
      margin-left: 20px;

      @include breakpoint-tb {
        margin-left: 0;
        margin-right: 15px;
      }
      @include breakpoint-pc {
        width: 20px;
      }
    }
    
    & .-ig {
      width: 36px;
      margin-left: 15px;

      @include breakpoint-tb {
        margin-left: 0;
        margin-right: 13px;
      }
      @include breakpoint-pc {
        width: 40px;
      }
    }
    
    & .-tw {
      width: 38px;

      @include breakpoint-pc {
        width: 40px;
      }
    }
  }
  
  &_copyright {
    &.-item2 {
      display: none;
      
      @include breakpoint-footerswitch {
        display: block;
        padding-bottom: 2px;
      }
    }

    &.-btm {
      display: block;
      
      @include breakpoint-footerswitch {
        display: none;
      }
    }
  }
}