.p3-text {
  ///// テキストシャドウ /////
  &.-shadow {
    &.-bk {
      -moz-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.57), 0px 0px 32px rgba(0, 0, 0, 0.9);
      -webkit-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.57), 0px 0px 32px rgba(0, 0, 0, 0.9);
      -ms-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.57), 0px 0px 32px rgba(0, 0, 0, 0.9);
      text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.57), 0px 0px 32px rgba(0, 0, 0, 0.9);
    }

    &.-wh {
      -moz-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px rgba(255, 255, 255, 1);
      -webkit-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px rgba(255, 255, 255, 1);
      -ms-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px rgba(255, 255, 255, 1);
      text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px rgba(255, 255, 255, 1);
    }
  }


  ///// 色付きテキスト /////
  &.-wh, & .-wh {
    color: color-wh(1) !important;
  }
  &.-bk01, & .-bk01 {
    color: color-black01(1) !important;
  }
  &.-gy02, & .-gy02 {
    color: color-gray02(1) !important;
  }
  &.-gy04, & .-gy04 {
    color: color-gray04(1) !important;
  }

  &.-inherit, & .-inherit {
    color: inherit !important;
  }
  
  
  ///// テキスト寄せ /////  
  &.-center {
    text-align: center;
  }


  ///// 下線付きテキスト /////
  //テキストにくっつく下線
  & .-underline1 {
    text-decoration: underline;
  }
  
  //テキストと離れた下線
  & .-underline2 {
    padding-bottom: 0.3em;
    box-sizing: border-box;
    
    @include breakpoint-pc {
      padding-bottom: 0.4em;
    }

    &.-black {
      background: linear-gradient(0deg, color-black01(1) 1px, transparent 1px,  transparent 100%);

      @include breakpoint-pc {
        background: linear-gradient(0deg, color-black01(1) 2px, transparent 2px,  transparent 100%);
      }
    }
  }

  ///// letter-spacing /////
  //マイナス値。句読点用
  &.-lsm050 {
    letter-spacing: -0.5em;
  }
  &.-lsm025 {
    letter-spacing: -0.25em;
  }
  //VA10相当
  &.-ls001 {
    letter-spacing: 0.01em;
  }
  //VA20相当、VA25相当
  &.-ls002 {
    letter-spacing: 0.02em;
  }
  //VA40相当
  &.-ls004 {
    letter-spacing: 0.04em;
  }
  //VA50相当
  &.-ls005 {
    letter-spacing: 0.05em;
  }
  //VA100相当
  &.-ls010 {
    letter-spacing: 0.10em;
  }


  //詰めた句読点
  &.-slimLs, & .-slimLs {
    letter-spacing: -0.5em;
  }

  //ルビ
  &.-ruby01 {
    word-break: break-all;

    rt {
      font-size: 1rem;
      word-break: break-all;
    }
  }
  
  //改行ルール
  &.-breaknormal {
    word-break: normal;
  }
  &.-breakall {
    word-break: break-all;
  }

  &.-tate {
    &.-upright {
      @include tategaki("upright");      

      & .-brackets {
        display: inline-block;
        vertical-align: middle; //ChromeとFireFoxとSafariとEdgeの全てで、一番左右のズレが少ないのがmiddle
        @include tategaki("sideways");
      }
    }
    
    &.-pcupright {
      @include breakpoint-pc {
        @include tategaki("upright");      
      }

      & .-brackets {
        @include breakpoint-pc {
          display: inline-block;
          vertical-align: middle; //ChromeとFireFoxとSafariとEdgeの全てで、一番左右のズレが少ないのがmiddle
          @include tategaki("sideways");      
        }
      }
    }
    
  }



  ///// リード文 /////
/*
  &.-lead01 {
    position: relative;
    //@include font-ntsan("m");
    font-size: 1.8rem;
    color: color-black01(1);
    text-align: justify;
    @include lineHeight(2.5, 2);

    @include breakpoint-sp {
      font-size: 2rem;
    }
    @include breakpoint-pc {
      font-size: 3.2rem;
    }
  }

  &.-lead02 {
    position: relative;
    //@include font-spm("sb");
    @include fontSize(2.8, 1.9);
    color: color-black01(1);
    text-align: justify;
    @include lineHeight(1.5, 1.6);

    @include breakpoint-pc {
    }
  }

  &.-lead03 {
    position: relative;
    //@include font-spm("sb");
    @include fontSize(2.8, 1.9);
    color: color-black01(1);
    text-align: justify;
    @include lineHeight(2.1, 1.7);

    @include breakpoint-pc {
    }
  }
*/

  ///// テキスト /////
  &.-t01 {
    @include font-ntsan("r");
    @include fontSize(1.6, 1.3);
    color: color-black01(1);
    text-align: justify;
    text-align-last: left;  //最終行のみはテキスト左寄せする
    @include lineHeight(2.3, 2);

    &.-bold {
      @include font-ntsan("b");
    }
    
    &.-center {
      text-align: center;
    }

    ///// テキスト上部を、line-heightで開いたスペース分上にずらして揃える /////
    &.-topajust {
      @include topAjust(2);

      @include breakpoint-pc {
        @include topAjust(2.3);
      }
    }
    
    &.-lh155 {
      @include lineHeight(1.55, 1.34);
    }
    
    &.-lh162 {
      @include lineHeight(1.62, 1.41);

      &.-topajust {
        @include topAjust(1.41);

        @include breakpoint-pc {
          @include topAjust(1.62);
        }
      }
    }
  }

  &.-t02 {
    @include font-ntsan("r");
    @include fontSize(2, 1.6);
    color: color-black01(1);
    text-align: justify;
    text-align-last: left;  //最終行のみはテキスト左寄せする
    @include lineHeight(2.3, 2);

    &.-bold {
      @include font-ntsan("b");
    }
    
    &.-center {
      text-align: center;
    }

    ///// テキスト上部を、line-heightで開いたスペース分上にずらして揃える /////
    &.-topajust {
      @include topAjust(2.3);
    }

    &.-lh150 {
      @include lineHeight(1.5, 1.5);

      &.-topajust {
        @include topAjust(1.5);
      }
    }
  }

  &.-t03 {
    @include font-lato("r");
    @include fontSize(1.8, 1.35);
    text-align: justify;
    
    &.-center {
      text-align: center;
    }
    
    &.-ls1 {
      letter-spacing: 1px;
    }

    ///// テキスト上部を、line-heightで開いたスペース分上にずらして揃える /////
    &.-topajust {
      @include topAjust(2.8);
    }
  }

  &.-t04 {
    @include font-akrobatExb();
    font-size: 2.7rem;
    line-height: 1.2;

    @include breakpoint-sp {
      font-size: 3.2rem;
    }
    @include breakpoint-tb {
      font-size: 3.35rem;
    }
    @include breakpoint-pc {
      font-size: 4.8rem;  //6rem * 0.8
    }
    @include breakpoint-contentinner {
      font-size: 5.4rem;  //6rem * 0.9
    }
    @include breakpoint-content {
      font-size: 6rem;
    }

    ///// テキスト上部を、line-heightで開いたスペース分上にずらして揃える /////
    &.-topajust {
      @include topAjust(1.2);
    }
  }

  &.-t05 {
    @include font-zmgo("r");
    @include fontSize(1.6, 1.4);
    color: color-black01(1);
    text-align: justify;
    line-height: 1.6;

    &.-medium {
      @include font-zmgo("m");
    }
    &.-bold {
      @include font-zmgo("bd");
    }

    ///// テキスト上部を、line-heightで開いたスペース分上にずらして揃える /////
    &.-topajust {
      @include topAjust(1.6);
    }
  }

  &.-t06 {
    @include font-zomin("r");
    font-size: 1.3rem;
    color: color-black01(1);
    text-align: justify;
    line-height: 2.2;

    @include breakpoint-tb {
//      font-size: 1.6rem;
    }
    @include breakpoint-pc {
      font-size: 1.6rem;
      letter-spacing: .05rem;
    }

    ///// テキスト上部を、line-heightで開いたスペース分上にずらして揃える /////
    &.-topajust {
      @include topAjust(2);
    }
    &.-leftajust, & .-leftajust {
      @include breakpoint-pc {
        @include leftAjust(2);
      }
    }
    
    &.-rightajust, & .-rightajust {
      @include breakpoint-pc {
        @include rightAjust(2);
      }
    }
  }

  &.-t07 {
    @include font-zmgo("r");
    @include fontSize(2.4, 1.9);
    color: color-black01(1);
    text-align: justify;
    line-height: 1.6;
    
    &.-bold {
      @include font-zmgo("bd");
    }

    &.-center {
      text-align: center;
    }

    ///// テキスト上部を、line-heightで開いたスペース分上にずらして揃える /////
    &.-topajust {
      @include topAjust(1.6);
    }
  }

  &.-t08 {
    @include font-ntsan("r");
    @include fontSize(1.8, 1.35);
    text-align: justify;
    line-height: 2;
    
    &.-center {
      text-align: center;
    }

    ///// テキスト上部を、line-heightで開いたスペース分上にずらして揃える /////
    &.-topajust {
      @include topAjust(2);
    }
  }

  &.-t09 {
    @include font-zmgo("r");
    font-size: 1.6rem;
    color: color-black01(1);
    text-align: justify;
    line-height: 1.1;
    
    @include breakpoint-tb {
      font-size: 1.6rem;
    }
    @include breakpoint-contenthalf {
      font-size: 1.8rem;
    }
    @include breakpoint-pc {
      font-size: 1.8rem;
    }
    @include breakpoint-contentinner {
      font-size: 2rem;
    }
    @include breakpoint-content {
      font-size: 2.2rem;
    }
    @include breakpoint-mainboxinner {
      font-size: 2.4rem;
    }

    &.-bold {
      @include font-zmgo("bd");
    }

    &.-center {
      text-align: center;
    }

    ///// テキスト上部を、line-heightで開いたスペース分上にずらして揃える /////
    &.-topajust {
      @include topAjust(1.1);
    }
  }

  &.-t10 {
    @include font-ntsan("r");
    @include fontSize(1.4, 1.13);
    color: color-black01(1);
    text-align: justify;
    text-align-last: left;  //最終行のみはテキスト左寄せする
    @include lineHeight(1.5, 1.5);

    &.-bold {
      @include font-ntsan("b");
    }
    
    &.-lh155 {
      @include lineHeight(1.55, 1.55);
    }
    
    &.-center {
      text-align: center;
    }

    ///// テキスト上部を、line-heightで開いたスペース分上にずらして揃える /////
    &.-topajust {
      @include topAjust(2.3);
    }
  }

  &.-t11 {
    @include font-akrobatExb();
    @include fontSize(3.2, 2.56);
    line-height: 1;
    
    &.-bk {
      color: color-bk(1);
    }
  }

  &.-t12 {
    @include font-zomin("r");
    @include fontSize(2.2, 1.76);
    text-align: justify;
    line-height: 1.7;

    @include breakpoint-pc {
    }
    
    &.-bold {
      @include font-zomin("bd");
    }
    &.-black {
      @include font-zomin("bk");
    }

    &.-center {
      text-align: center;
    }
    
    &.-thanks {
      font-size: 1.76rem;
      text-align: left;

      @media all and (min-width: 520px) {
        text-align: center;
      }
      @include breakpoint-pc {
        font-size: 2rem;
      }
      @include breakpoint-contentinner {
        font-size: 2.2rem;
      }
    }
  }

  &.-t13 {
    @include font-zmgo("m");
    @include fontSize(1.8, 1.35);
    text-align: justify;
    line-height: 2;
        
    &.-bold {
      @include font-zmgo("bd");
    }

    &.-center {
      text-align: center;
    }
    
    &.-red02 {
      color: color-red02(1);
    }
    
    &.-projectnamejp {
      font-size: 1.35rem;
      @include lineHeight(1.7, 1.7);

      @include breakpoint-pc {
        font-size: 1.8vw;  //1.8rem / 1000px * 100
      }
      @include breakpoint-contentinner {
        font-size: 1.8rem;
      }
    }
  }

  &.-t14 {
    @include font-yugo("m");
    @include fontSize(1.8, 1.6);
    color: color-black01(1);
    line-height: 1.8;
  }

  &.-t15 {
    @include font-ntsan("b");
    @include fontSize(3.2, 2.56); //3.2 * 0.8 = 2.56
    color: color-black01(1);
    text-align: justify;
    text-align-last: left;  //最終行のみはテキスト左寄せする
    @include lineHeight(1, 1);
    letter-spacing: 0.05em; //VA40

    &.-center {
      text-align: center;
    }
    
    &.-projectnameen {
      font-size: 2.56rem; //3.2 * 0.8 = 2.56
      @include lineHeight(1.3, 1.3);
      text-align: left;
      word-break: break-all;

      @include breakpoint-pc {
        font-size: 3.2vw;  //3.2rem / 1000px * 100
      }
      @include breakpoint-contentinner {
        font-size: 3.2rem;
      }
    }
  }

  &.-t16 {
    @include font-ntsan("r");
    @include fontSize(1.6, 1.3);
    color: color-black01(1);
    text-align: justify;
    text-align-last: left;  //最終行のみはテキスト左寄せする
    @include lineHeight(2.3, 2);

    &.-center {
      text-align: center;
    }
  }

  &.-t17 {
    @include font-zmgo("r");
    font-size: 1.8rem;
    color: color-black01(1);
    text-align: justify;
    line-height: 1.1;
    
    @include breakpoint-tb {
      font-size: 1.8rem;
    }
    @include breakpoint-contenthalf {
      font-size: 2.1rem;
    }
    @include breakpoint-pc {
      font-size: 2.1rem;
    }
    @include breakpoint-contentinner {
      font-size: 2.3rem;
    }
    @include breakpoint-content {
      font-size: 2.5rem;
    }
    @include breakpoint-mainboxinner {
      font-size: 2.8rem;
//      font-size: 2.4rem;
    }

    &.-bold {
      @include font-zmgo("bd");
    }

    &.-center {
      text-align: center;
    }

    ///// テキスト上部を、line-heightで開いたスペース分上にずらして揃える /////
    &.-topajust {
      @include topAjust(1.1);
    }
  }

  &.-t18 {
    @include font-akrobatExb();
    @include fontSize(1.7, 1.36);
    line-height: 1;
    
    &.-bk {
      color: color-bk(1);
    }
    &.-red02 {
      color: color-red02(1);
    }
  }

  &.-t19 {
    @include font-akrobatExb();
    @include fontSize(2.4, 1.92); //2.4 * 0.8 = 1.92
    line-height: 1;
  }

  &.-t20 {
    @include font-akrobatBd();
    @include fontSize(2.4, 1.92); //2.4 * 0.8 = 1.92
    line-height: 1;
  }

/*
  ///// リンク /////
  &.-link01 {
    color: color-black01(1);
    text-decoration: underline;
  }
*/

  ///// リスト /////
/*
  &.-list01 {
    list-style-type: none;
    @include listIndent(3, 2.5);  //2.0rem + 10px, 1.5rem + 10px;
  
    li {
      position: relative;
      padding: 6px 14px 12px 40px;
      border-bottom: 1px solid color-sub01(1);
      box-sizing: border-box;
      //@include font-spm("m");
      @include fontSize(2, 1.5);
      color: color-black01(1);
      @include lineHeight(1.7, 1.24);
      text-align: justify;
      
      @include breakpoint-pc {
        padding: 15px 30px 25px 60px;
      }

      &:last-of-type {
        border-bottom-style: none;
      }

      &:before {
        position: relative;
        top: 2px;
        padding-right: 10px;
        box-sizing: border-box;
        content: "\02713"; //チェックマーク
        //@include font-ntsan("h");
        @include fontSize(2.7, 1.9);
        color: color-black01(1);
      }
    }
  }

  &.-list02 {
    padding: 0;
    @include fontSize(1.6, 1.3);
    color: color-black01(1);
    text-align: justify;
    @include listIndent(1.6, 1.3);
  
    li {
      box-sizing: border-box;
      line-height: 2;
      
      &:before {
        content: "\030FB"; //全角中点
      }
    }
  }

  &.-list03 {
    padding: 0;
    @include fontSize(1.4, 1.2);
    color: color-black01(1);
    text-align: justify;
    @include listIndent(1.9, 1.7);  // 14px + 5px(余白), 12px + 5px(余白)
  
    li {
      box-sizing: border-box;
      line-height: 2;
      
      &:before {
        content: "\0203B"; //全角※
        padding-right: 5px; //余白5px
        box-sizing: border-box;
      }
    }
  }
*/

  ///// SVGテキスト /////
  &.-svgtext {
    //ヘッダーの英語テキスト
    &.-headerlogo {
      @include svgSize(110, 26);
//      @include svgSize(74, 17.5);
      fill: color-white02(1); //塗りつぶし
  
      @include breakpoint-pc {
        @include svgSize(140.8, 32.8);  // 176 * 0.8, 41 * 0.8
        fill: color-black01(1); //塗りつぶし
      }
      @include breakpoint-contentinner {
        @include svgSize(158.4, 36.9);  // 176 * 0.9, 41 * 0.9
      }
      @include breakpoint-content {
        @include svgSize(176, 41);
      }
    }

    //トップページKV内の英語テキスト
    &.-frontkvlogo {
      @include svgSize(358, 84.5);
      fill: none; //塗りつぶし無し
      stroke: color-wh(1);  //枠線の色
      stroke-miterlimit: 10;  //この値は、SVGのまま変えない
  
      @include breakpoint-pc {
        @include svgSize(913, 215);
      }

    }
  }
}