@mixin btnsize($pc, $sp) {
  width: 100%;
  @include breakpoint-sp {
    max-width: $sp;
  }
  @include breakpoint-pc {
    width: $pc;
  }
}

@mixin btn($status) {
  clear: both;
  text-align: center;

  &_inner, &_innerbtn, a {
    display: block;
    position: relative;
    width: 100%;
    padding: 11px 0 12px;
    box-sizing: border-box;
    //@include font-ntsan("m");
    color: color-wh(1);
    line-height: 1;
    @extend %hover;
    cursor: pointer;

    @include breakpoint-pc {
    }
  }

  &_innerbtn {
    padding: 0;
  }

  ///// 緑ボタン /////
  @if $status == 1 {
    &_inner, a {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-justify-center;
      @include flex-align-items-center;
      @include flex-align-content-center;
      padding: 16px 10px 18px;
      @include font-ntsan("m");
      font-size: 2rem;
      color: color-wh(1);
      text-align: center;
      background-color: color-green01(1);

      @include breakpoint-sp {
        padding: 16px 15px 21px;
        font-size: 2.2rem;
      }
      @include breakpoint-pc {
        font-size: 2.2rem;
      }
      @include breakpoint-content {
      }
    }

    &_inner {
      a {
        padding: 0;
      }
    }
    
    .icon {
      margin-right: 19px;

      @include breakpoint-pc {
        margin-right: 19px;
      }
    }
  }
  
  ///// 細ボタン /////
  @else if $status == 2 {
    &_inner, a {
      padding: 6px 20px 7px;
      @include font-ntsan("b");
      @include fontSize(1.4, 1.4);
      color: color-black01(1);
      text-align: center;
      background-color: color-wh(1);
    }
  }
  
  ///// 矢印ボタン(右向き、白) /////
  @else if $status == 3 {
    &_inner, a {
      padding: 7px 20px 15px;
      @include font-ntsan("b");
      @include fontSize(1.6, 1.6);
      color: color-wh(1);
      text-align: left;
      background-image: url("../images/common/icon/arw_line_wh_r.png");
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  
  ///// 矢印ボタン(右向き、赤) /////
  @else if $status == 4 {
    &_inner, a {
      padding: 7px 20px 15px;
      @include font-ntsan("b");
      @include fontSize(1.6, 1.6);
      color: color-red06(1);
      text-align: left;
      background-image: url("../images/common/icon/arw_line_rd_r.png");
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  
  ///// 矢印ボタン(左向き、白) /////
  @else if $status == 5 {
    &_inner, a {
      padding: 7px 20px 15px;
      @include font-ntsan("b");
      @include fontSize(1.6, 1.6);
      color: color-wh(1);
      text-align: right;
      background-image: url("../images/common/icon/arw_line_wh_l.png");
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  
  ///// 矢印ボタン(左向き、赤) /////
  @else if $status == 6 {
    &_inner, a {
      padding: 7px 20px 15px;
      @include font-ntsan("b");
      @include fontSize(1.6, 1.6);
      color: color-red06(1);
      text-align: right;
      background-image: url("../images/common/icon/arw_line_rd_l.png");
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  
  ///// 3連矢印ボタン(右向き、黒) /////
  @else if $status == 7 {
    &_inner, a {
      padding: 7px 20px 10px;
      border-bottom: 1px solid color-black01(1);
      @include font-ntsan("b");
      @include fontSize(1.6, 1.3);
      color: color-black01(1);
      text-align: center;
      
      @include breakpoint-pc {
        padding: 7px 20px 15px;
      }

      span {
        @include flexbox;
        @include flex-wrap-nowrap;
        @include flex-justify-center;
        @include flex-align-items-center;
        @include flex-align-content-center;
        position: relative;
        padding-left: 8px;
        box-sizing: border-box;
      
        @include breakpoint-pc {
          padding-left: 10px;
        }
        
        &:after {
          display: inline-block;
          position: relative;
          content: "";
          width: 22px;
          height: 10px;
          margin-left: 12px;
          background-image: url("../images/common/icon/arrow_blink_gray.gif");
          background-position: right center;
          background-repeat: no-repeat;
          background-size: 21px auto;
      
          @include breakpoint-pc {
            width: 26px;
            height: 12px;
            margin-left: 18px;
            background-size: 26px auto;
          }
        }
      }
    }
  }
  
  ///// 角丸ボタン /////
  @else if $status == 8 {
    &_inner, a {
      padding: 10px 0 12px;
      border-radius: 100vh;
      @include font-zmgo("m");
      @include fontSize(1.6, 1.35);
      color: color-wh(1);
      text-align: center;
      background-color: color-black01(1);
    }
  }

  ///// 3連矢印ボタン(右向き、茶色白抜き) /////
  @else if $status == 9 {
    &_inner, a {
      padding: 0;
      @include font-ntsan("b");
      @include fontSize(1.6, 1.3);
      color: color-wh(1);
      background-color: color-brown01(1);
        
      &:after {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 50px;
        transform: translate(-50%, -50%);
        content: "";
        width: 22px;
        height: 10px;
        margin-left: 12px;
        background-image: url("../images/common/icon/arrow_blink_white.gif");
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 21px auto;
        pointer-events: none;
    
        @include breakpoint-pc {
          width: 26px;
          height: 12px;
          margin-left: 18px;
          background-size: 26px auto;
        }
      }
    }
    
    &_btn {
      display: block;
      width: 100%;
      height: 100%;
      padding: 8px 50px 9px 0;
      color: color-wh(1);
      text-align: center;
    }
  }
  
  ///// 下線ボタン /////
  @else if $status == 10 {
    &_inner, a {
      padding: 0;
      @include font-ntsan("b");
      @include fontSize(1.5, 1.5);
      border-bottom: 1px solid color-black01(1);
    }

    &_btn {
      width: 100%;
      height: 100%;
      padding: 10px 0 12px;
      color: color-black01(1);
      text-align: center;
    }
  }

  ///// 3連矢印リンク(右向き、茶色白抜き) /////
  @else if $status == 11 {
    &_inner, a {
      padding: 7px 20px 10px;
      @include font-ntsan("b");
      @include fontSize(1.6, 1.3);
      color: color-wh(1);
      background-color: color-brown01(1);
      text-align: center;

      span {
        @include flexbox;
        @include flex-wrap-nowrap;
        @include flex-justify-center;
        @include flex-align-items-center;
        @include flex-align-content-center;
        position: relative;
        padding-left: 8px;
        box-sizing: border-box;
      
        @include breakpoint-pc {
          padding-left: 10px;
        }
        
        &:after {
          display: inline-block;
          position: relative;
          content: "";
          width: 22px;
          height: 10px;
          margin-left: 12px;
          background-image: url("../images/common/icon/arrow_blink_white.gif");
          background-position: right center;
          background-repeat: no-repeat;
          background-size: 21px auto;
      
          @include breakpoint-pc {
            width: 26px;
            height: 12px;
            margin-left: 18px;
            background-size: 26px auto;
          }
        }
      }
    }
  }
  
  ///// 角丸ボタン /////
  @else if $status == 12 {
    &_inner, a {
      padding: 5px 0 7px 2px;
      border-radius: 100vh;
      box-sizing: border-box;
      @include font-zmgo("m");
      @include fontSize(1.6, 1.35);
      color: color-wh(1);
      text-align: center;
      background-color: color-red02(1);
        
      &:after {
        display: inline-block;
        position: relative;
        content: "";
        width: 22px;
        height: 10px;
        margin-left: 6px;
        background-image: url("../images/common/icon/arrow_blink_white.gif");
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 21px auto;
    
        @include breakpoint-pc {
          width: 26px;
          height: 12px;
          margin-left: 11px;
          background-size: 26px auto;
        }
      }
    }
  }
  
  ///// 角丸ボタン(黒背景、矢印なし) /////
  @else if $status == 13 {
    &_inner, a {
      padding: 8px 15px 10px;
      border-radius: 100vh;
      box-sizing: border-box;
      @include font-zmgo("m");
      @include fontSize(1.6, 1.35);
      color: color-wh(1);
      text-align: center;
      background-color: color-black01(1);
    }
  }
}


.p0-btn1 {
  position: relative;
  width: 100%;
  max-width: 340px;
  @include btn(1);
  box-sizing: border-box;
  background-color: color-wh(1);

  @include breakpoint-pc {
  }
}

.p0-btn2 {
  position: relative;
  width: 100%;
  max-width: 340px;
  @include btn(2);
  box-sizing: border-box;
  background-color: color-wh(1);

  @include breakpoint-pc {
  }
}

.p0-btn3 {
  position: relative;
  width: 100%;
  max-width: 186px;
  @include btn(3);
  box-sizing: border-box;

  @include breakpoint-pc {
  }
}

.p0-btn4 {
  position: relative;
  width: 100%;
  max-width: 186px;
  @include btn(4);
  box-sizing: border-box;

  @include breakpoint-pc {
  }
}

.p0-btn5 {
  position: relative;
  width: 100%;
  max-width: 186px;
  @include btn(5);
  box-sizing: border-box;

  @include breakpoint-pc {
  }
}

.p0-btn6 {
  position: relative;
  width: 100%;
  max-width: 186px;
  @include btn(6);
  box-sizing: border-box;

  @include breakpoint-pc {
  }
}

.p0-btn7 {
  position: relative;
  width: 147px;
  max-width: 300px;
  @include btn(7);
  box-sizing: border-box;

  @include breakpoint-pc {
    width: 100%;
  }
  
  &.-wide {
    width: 217px;

    @include breakpoint-pc {
      width: 100%;
    }
  }
}

.p0-btn8 {
  position: relative;
  width: 100px;
  @include btn(8);
  box-sizing: border-box;
}

.p0-btn9 {
  position: relative;
  width: 285px;
  @include btn(9);
  box-sizing: border-box;
}

.p0-btn10 {
  position: relative;
  width: 150px;
  @include btn(10);
  box-sizing: border-box;
}

.p0-btn11 {
  position: relative;
  width: 285px;
  @include btn(11);
  box-sizing: border-box;
}

.p0-btn12 {
  position: relative;
  width: 150px;
  @include btn(12);
  box-sizing: border-box;
}

.p0-btn13 {
  display: inline-block;
  position: relative;
  width: auto;
  @include btn(13);
  box-sizing: border-box;
}


///// 横並びボタン /////
.p0-flexbtns {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-column;
  @include flex-justify-flex-start;
  @include flex-align-items-stretch;

  @include breakpoint-pc {
    @include flex-wrap-nowrap;
    @include flex-direction-row;
  }
  
  &.-horizontal {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-column;
    @include flex-justify-center;
    @include flex-align-items-center;
    @include flex-align-content-center;
  
    @include breakpoint-pc {
      @include flex-wrap-wrap;
      @include flex-direction-column;
    }
  }
  
  &.-left {
    @include flex-justify-flex-start;
    
    &.-spcenter {
      @include flex-justify-center;
      @include flex-align-items-center;
  
      @include breakpoint-pc {
        @include flex-justify-flex-start;
      }
    }
  }
  
  &.-center {
    @include flex-justify-center;
    @include flex-align-items-center;
    
    @include breakpoint-pc {
      @include flex-align-items-stretch;
    }
  }
  
  &.-right {
    @include flex-justify-flex-end;
    
    &.-spcenter {
      @include flex-justify-center;
      @include flex-align-items-center;
  
      @include breakpoint-pc {
        @include flex-justify-flex-end;
      }
    }
  }
  
  &_item {
    margin-bottom: 20px;
    
    @include breakpoint-pc {
      margin-bottom: 0;
      margin-right: 2vw;
    }
    @include breakpoint-content {
      margin-right: 20px;
    }
    
    &:last-of-type {
      margin-bottom: 0;

      @include breakpoint-pc {
        margin-right: 0;
      }
      @include breakpoint-content {
        margin-right: 0;
      }
    }
  }
}