body {
  color: color-black01(1);
  background-color: color-wh(1);
  width: 100%;
  @include fontSize(1.6, 1.5);
  line-height: 1;
  ////@include font-ntsan("r");

  @include breakpoint-pc {
  }
}

a {
  color: color-black01(1);
  text-decoration: none;
}

::selection {
  background-color: color-gray01(1);
}
::-moz-selection {
  background-color: color-gray01(1);
}

//電話番号リンク
.js-tellink {
  a {
    color: inherit;
  }
}
