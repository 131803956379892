@function color-wh($opacity) {
  @return rgba(255, 255, 255, $opacity);
}
@function color-bk($opacity) {
  @return rgba(0, 0, 0, $opacity);
}


@function color-black01($opacity) {
  @return rgba(56, 54, 46, $opacity);  //#38362e、黒系1
}

@function color-gray01($opacity) {
  @return rgba(209, 208, 208, $opacity);  //#d1d0d0、灰色系1
}
@function color-gray02($opacity) {
  @return rgba(150, 150, 150, $opacity);  //#969696、灰色系2
}
@function color-gray03($opacity) {
  @return rgba(138, 137, 132, $opacity);  //#8a8984、灰色系3
}
@function color-gray04($opacity) {
  @return rgba(162, 162, 162, $opacity);  //#a2a2a2、灰色系4
}


@function color-white01($opacity) {
  @return rgba(248, 248, 248, $opacity);  //#f8f8f8、白系1
}
@function color-white02($opacity) {
  @return rgba(225, 225, 225, $opacity);  //#e1e1e1、白系2
}


@function color-red01($opacity) {
  @return rgba(152, 116, 112, $opacity);  //#987470、赤系1、メニューカレント色(PC時)
}
@function color-red02($opacity) {
  @return rgba(231, 34, 45, $opacity);  //#e7222d、赤系2、メニューホバー色3
}
@function color-red03($opacity) {
  @return rgba(183, 38, 45, $opacity);  //#b7262d、赤系3、メニューホバー色6
}
@function color-red04($opacity) {
  @return rgba(203, 168, 164, $opacity);  //#cba8a4、赤系4、メニューカレント色(スマホ時)
}
@function color-red05($opacity) {
  @return rgba(177, 143, 139, $opacity);  //#b18f8b、赤系5、お知らせ一覧の区切り線色
}
@function color-red06($opacity) {
  @return rgba(133, 106, 103, $opacity);  //#856a67、赤系6、赤系ボタン文字色
}

@function color-green01($opacity) {
  @return rgba(54, 172, 74, $opacity);  //#36ac4a、緑系1、メニューホバー色2
}
@function color-green02($opacity) {
  @return rgba(67, 105, 24, $opacity);  //#436918、緑系2、メニューホバー色5
}
@function color-green03($opacity) {
  @return rgba(135, 173, 101, $opacity);  //#87ad65、緑系3、メニューホバー色7
}
@function color-green04($opacity) {
  @return rgba(105, 184, 45, $opacity);  //#69b82d、緑系4、野菜図鑑-通常野菜色
}
@function color-green05($opacity) {
  @return rgba(110, 130, 95, $opacity);  //#6e825f、緑系5、農活倶楽部のテキスト画像背景色
}


@function color-orange01($opacity) {
  @return rgba(237, 143, 37, $opacity);  //#ed8f25、オレンジ系1、メニューホバー色4
}
  
@function color-purple01($opacity) {
  @return rgba(217, 147, 204, $opacity); //#d993cc、紫系1
}
@function color-purple02($opacity) {
  @return rgba(170, 82, 155, $opacity); //#aa529b、紫系2、メニューホバー色1
}

@function color-brown01($opacity) {
  @return rgba(176, 143, 139, $opacity); //#b08f8b、茶色系1
}
@function color-brown02($opacity) {
  @return rgba(230, 222, 221, $opacity); //#e6dedd、茶色系2
}


@function color-yellow01($opacity) {
  @return rgba(255, 251, 239, $opacity);  //#fffbef、黄色系1
}
@function color-yellow02($opacity) {
  @return rgba(221, 220, 201, $opacity);  //#dddcc9、黄色系2
}
