////// text settings //////
@mixin fontSize($pc, $sp) {
  font-size: $sp + rem;

  @include breakpoint-pc {
    font-size: $pc + rem;
  }
}

@mixin lineHeight($pc, $sp) {
  line-height: $sp;

  @include breakpoint-pc {
    line-height: $pc;
  }
}

@mixin align($status) {
  text-align: left !important;
  @if $status == 'l' {
    @include breakpoint-pc {
      text-align: left !important;
    }
  } @else if $status == 'r' {
    @include breakpoint-pc {
      text-align: right !important;
    }
  } @else if $status == 'c' {
    @include breakpoint-pc {
      text-align: center !important;
    }
  }
}
.align-l {
  @include align("l");
}
.align-r {
  @include align("r");
}
.align-c {
  @include align("c");
  
  &.-spleft {
    @include align("l");

    @include breakpoint-tb {
      @include align("c");
    }
  }
  
  &.-tbleft {
    @include align("l");

    @include breakpoint-pc {
      @include align("c");
    }
  }
}

///// 左右にくっつく形で等間隔配置 /////
.align-jsb {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-justify-space-between;

  @include breakpoint-pc {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-justify-space-between;
  }
  
  &.-spleft {
    display: block;

    @include breakpoint-tb {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-justify-space-between;
    }
  }
  
  &.-tbleft {
    display: block;

    @include breakpoint-pc {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-justify-space-between;
    }
  }
}

// flexでcenter //
.flex-justify-center {
  @include flexbox;
  @include flex-justify-center;
}


.br, %br {
  display: none;
  @include breakpoint-pc {
    display: inline-block;
  }
}

/*
｡ : &#xFF61;
､ : &#xFF64;
･(和文) : &#xFF65;
·(欧文) : &#x00B7;
*/



////// list //////
@mixin listIndent($font-pc, $font-sp) {
  & li {
    text-indent: - $font-sp + rem;
    padding-left: $font-sp + rem;
    box-sizing: border-box;

    @include breakpoint-pc {
      text-indent: - $font-pc + rem;
      padding-left: $font-pc + rem;
    }
  }
}


////// table scroll //////
//usage:tableの一階層上に追加
@mixin tableScroll($tableW) {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  // scroll bar
  &:-webkit-scrollbar{
   height: 8px;
  }
  &::-webkit-scrollbar-track{
   background: #333;
  }
  &::-webkit-scrollbar-thumb {
   background: #999;
  }
  // table設定
  & table {
    width: $tableW + px;
  }
}


////// img cleary (transform) //////
@mixin imgCleary() {
  backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
}

////// tategaki //////
@mixin tategaki($status) {
  writing-mode: vertical-rl;
  text-align: left;
  text-orientation: upright; // 正立/横倒し mixed / upright / sideways
  //text-combine-upright: all; // 縦中横 all / digits 2~4
  //-ms-text-combine-horizontal: : digits 2;

  @if $status == 'upright' {
    text-orientation: upright; // 正立/横倒し mixed / upright / sideways
  } @else if $status == 'sideways' {
    text-orientation: sideways;
  } @else if $status == 'mixed' {
    text-orientation: mixed;
  }

  & .combine {
    text-combine-upright: all;
  }
}

//解除
@mixin tategaki-off() {
  writing-mode: horizontal-tb;
  text-align: inherit;
  text-orientation: inherit; // 正立/横倒し mixed / upright / sideways
  //text-combine-upright: all; // 縦中横 all / digits 2~4
  //-ms-text-combine-horizontal: : digits 2;
   & .combine {
     text-combine-upright: none;
   }
}

////// mouse over setings //////
.hover, %hover {
  filter: alpha(opacity=100);
  opacity: 1;
  transition: all 0.3s ease-in-out;

  &:hover, &:focus {
    filter: alpha(opacity=70);
    opacity: 0.7;
  }
  
  &.-underline {
    filter: alpha(opacity=100);
    opacity: 1;
    transition: all 0.3s ease-in-out;
  
    &:hover, &:focus {
      filter: alpha(opacity=100);
      opacity: 1;
      text-decoration: underline;
    }
  }
}

.nohover, %nohover {
  filter: alpha(opacity=100);
  opacity: 1;

  &:hover, &:focus {
    filter: alpha(opacity=100);
    opacity: 1;
  }
}


////// anchor //////
.anchor, %anchor {
  clear: both;
  position: relative;
  top: -60px;
  display: block;
  
  @include breakpoint-pc {
    top: -72px;
  }
  @include breakpoint-content {
    top: -80px;
  }
}

////// clearfix //////
@mixin clearfix() {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

////// ... //////
//@include abridgement(行間,文字数,表示したい行数,#背景色);
@mixin abridgement($line-height,$font-size,$lines,$color){
  line-height: $line-height;
  height: $font-size*$line-height*$lines+px;
  overflow: hidden;
  position: relative;
  word-break: break-all;
  text-align:justify;
  &:before,
  &:after{
    position: absolute;
  }
  &:before{
    content: '...';
    background: $color;
    right: 0px;
    text-align: center;
    width:1em !important;
    top:$font-size*$line-height*($lines - 1) +px;
  }
  &:after {
    content: '';
    height: 100%;
    width: 100%;
    background: 0px;
    z-index: 2;
    background: $color;
  }
}

//1行以下略
@mixin ellipsisOneRow() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// inner box padding setting
@mixin innerBox() {
  padding-left: 1vw;
  padding-right: 1vw;

  @include breakpoint-pc {
    padding-left: 1.5vw;
    padding-right: 1.5vw;
  }

  @media (min-width:900px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@mixin innerBox2() {
  width: 100%;
  max-width: 900px;

  @include breakpoint-pc {
  }
  @include breakpoint-mainboxinner {
    
  }
}


//レスポンシブ用可変画像サイズ
//使用例：@include resImgSize(1278px, 513px, 1380px);//(画像幅1278px、画像高513px、vw算出時の基準となる幅1380px)
@mixin resImgSize($imgW, $imgH, $boxW) {
  width: $imgW;
  height: $imgH;
  
  @include breakpoint-pc {
    width: ($imgW / $boxW) * 100 + 0vw;
    height: ($imgH / $boxW) * 100 + 0vw;
  }
  @include breakpoint-mainboxinner {
    width: $imgW;
    height: $imgH;
  }
}


// テキスト上部を、line-heightで開いたスペース分上にずらして揃える
//line-heightが1.8、テキストの高さが1。差分の高さの半分が、上部スペースの高さに相当する。 ( 1 - 1.8 ) / 2 = -0.4。単位はem
//使用例：@include topAjust(1.8);//(line-height: 1.8)
@mixin topAjust($lineH) {
  margin-top: ( ( 1 - $lineH ) / 2) + 0em !important;
}


// テキスト左部を、line-heightで開いたスペース分左にずらして揃える(縦書き用)
//line-heightが1.8、テキストの高さが1。差分の高さの半分が、上部スペースの高さに相当する。 ( 1 - 1.8 ) / 2 = -0.4。単位はem
//使用例：@include leftAjust(1.8);//(line-height: 1.8)
@mixin leftAjust($lineH) {
  margin-left: ( ( 1 - $lineH ) / 2) + 0em !important;
}


// テキスト右部を、line-heightで開いたスペース分右にずらして揃える(縦書き用)
//line-heightが1.8、テキストの高さが1。差分の高さの半分が、上部スペースの高さに相当する。 ( 1 - 1.8 ) / 2 = -0.4。単位はem
//使用例：@include topAjust(1.8);//(line-height: 1.8)
@mixin rightAjust($lineH) {
  margin-right: ( ( 1 - $lineH ) / 2) + 0em !important;
}


// 角丸画像
@mixin imgRadius($radius, $breakpoint) {
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 50px / $breakpoint * 100vw;

  @media all and (min-width: ($breakpoint)) {
    border-radius: $radius;
  }
}
