.c-info {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-flex-start;
  @include flex-align-items-stretch;
  gap: 20px 0;
  padding: 20px 30px 30px;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: color-brown02(1);

  @include breakpoint-pc {
    @include flex-wrap-nowrap;
    gap: 0 60px;
    padding: 20px 0;
  }
  
  &.-recipe {
    background-color: color-brown02(1);
  }
  
  &.-marche, &.-noukatsu {
    background-color: color-gray01(1);
  }


  &_item {
    width: 100%;
    
    &.-head {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-column;
      @include flex-justify-center;
      @include flex-align-items-center;
      @include flex-shrink-0;
      width: 100%;

      @include breakpoint-pc {
        width: 30%;
        min-width: 200px;
      }
      
      &.-top {
        @include flex-justify-flex-start;
      }
      
      &.-author {
        margin-bottom: 25px;
        
        @include breakpoint-pc {
          margin-bottom: 0;
        }
      }
    }
    
    &.-body {
      width: 100%;
      box-sizing: border-box;

      @include breakpoint-pc {
        width: calc(70% - 60px);
        padding-bottom: 10px;
      }
      
      a {
        text-decoration: underline;
      }
    }
  }

  .c-info_item.-head {
    padding-bottom: 15px;
    border-bottom: 1px solid color-bk(1);
    box-sizing: border-box;

    @include breakpoint-pc {
      padding-bottom: 0;
      border-bottom-style: none;
      border-right: 1px solid color-bk(1);
    }
  }

  .c-info_item.-body {
    box-sizing: border-box;

    @include breakpoint-pc {
      padding-right: 60px;
    }
    
    a {
      color: color-black01(1);
      
      &:hover {
        @extend %hover;
      }
    }
  }
}
