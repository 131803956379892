.p3-headline {
  position: relative;
  text-align: center;
  color: color-black01(1);
  

  ///// letter-spacing /////
  //マイナス値。句読点用
  &.-lsm050 {
    letter-spacing: -0.5em;
  }
  &.-lsm025 {
    letter-spacing: -0.25em;
  }
  //VA10相当
  &.-ls001 {
    letter-spacing: 0.01em;
  }
  //VA20相当、VA25相当
  &.-ls002 {
    letter-spacing: 0.02em;
  }
  //VA40相当
  &.-ls004 {
    letter-spacing: 0.04em;
  }
  //VA50相当
  &.-ls005 {
    letter-spacing: 0.05em;
  }
  //VA100相当
  &.-ls010 {
    letter-spacing: 0.10em;
  }


  ///// 汎用タイトル /////
  &.-ttl01 {
    position: relative;
    @include font-zomin("bk");
    @include fontSize(3, 2);
    color: color-black01(1);
    @include lineHeight(2.3, 2);
    
    &.-left {
      text-align: left;
    }
    &.-center {
      text-align: center;
    }
    &.-justify {
      text-align: justify;
    }
    
    &.-marchesubttl {
      &::before {
        display: inline-block;
        position: relative;
        transform: translateY(1px); //位置微調整
        width: 24px;  //30px * 0.8
        height: 24px;  //30px * 0.8
        content: "";
        margin-right: 6px;  //8px * 0.8
        background-image: url("../images/common/icon/marchesubttl.png");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;

        @include breakpoint-pc {
          width: 30px;
          height: 30px;
          margin-right: 8px;
        }
      }
      
    }
  }

  &.-ttl02 {
    position: relative;
    @include font-ntsan("b");
    @include fontSize(2, 1.7);
    color: color-black01(1);
    @include lineHeight(2.3, 2);
    
    &.-left {
      text-align: left;
    }
    &.-center {
      text-align: center;
    }
    &.-justify {
      text-align: justify;
    }
  }

  &.-ttl03 {
    position: relative;
    @include font-zomin("bk");
    @include fontSize(4, 3.2);  //4 * 0.8 = 3.2
    color: color-black01(1);
    @include lineHeight(1.85, 1.75);
    
    &.-left {
      text-align: left;
    }
    &.-center {
      text-align: center;
    }
    &.-justify {
      text-align: justify;
    }
  }


  ///// トップページ英語(TOPICS) /////
  &.-enttl {
    @include font-lato("b");
    @include fontSize(4, 3);
    letter-spacing: 2px;
  }
  
  ///// トップページ動画 /////
  &.-moviettl {
    display: inline-block;
    position: relative;
    width: 194px;
    height: 45px;

    @include breakpoint-pc {
      display: block;
      width: 278px; //347px * 0.8
      height: 65px; //81px * 0.8
    }
    @include breakpoint-contentinner {
      width: 313px; //347px * 0.9
      height: 73px; //81px * 0.9
    }
    @include breakpoint-content {
      width: 347px;
      height: 81px;
    }
  }
  
  ///// トップページアウトラインタイトル /////
  &.-outlinettl {
    display: none;
    position: relative;
    @include font-zomin("bk");
    font-size: 2.5rem;
    color: color-black01(1);

    @include breakpoint-pc {
      display: block;
      font-size: 3.2rem;  //40px * 0.8
    }
    @include breakpoint-contentinner {
      font-size: 3.6rem;  //40px * 0.9
    }
    @include breakpoint-content {
      font-size: 4rem;
    }
  }
  
  ///// トップページアウトラインサブタイトル /////
  &.-outlinesubttl {
    display: block;
    position: relative;
    @include font-zomin("bk");
    font-size: 2.5rem;
    color: color-black01(1);
    @include lineHeight(1.85, 1.75);
    text-align: left;

    @include breakpoint-tb {
//      font-size: 3.2rem;  //40px * 0.8
    }
    @include breakpoint-pc {
      @include tategaki("upright");      
      font-size: 3.2rem;  //40px * 0.8
    }
    @include breakpoint-contentinner {
      font-size: 3.6rem;  //40px * 0.9
    }
    @include breakpoint-content {
      font-size: 4rem;
    }

    ///// テキスト上下左右を、line-heightで開いたスペース分ずらして揃える /////      
    &.-leftajust, & .-leftajust {
      @include breakpoint-pc {
        @include leftAjust(1.85);
      }
    }
    
    &.-rightajust, & .-rightajust {
      @include breakpoint-pc {
        @include rightAjust(1.85);
      }
    }
  }
  
  ///// 下層ページKVタイトル /////
  &.-lowerpageTtl {
    display: block;
    position: relative;
    @include font-zomin("bk");
    font-size: 2.5rem;
    color: color-black01(1);
    @include lineHeight(1.85, 1.75);

    @include breakpoint-pc {
      font-size: 3.2rem;  //40px * 0.8
    }
    @include breakpoint-contentinner {
      font-size: 3.6rem;  //40px * 0.9
    }
    @include breakpoint-content {
      font-size: 4rem;
    }
    
    &.-left {
      text-align: left;
    }
    &.-center {
      text-align: center;
    }
    &.-justify {
      text-align: justify;
    }
  }

  &.-lowerpageSubTtl {
    position: relative;
    @include font-akrobatExb();
    @include fontSize(1.8, 1.35);
    letter-spacing: 2px;
    
    &.-center {
      text-align: center;
    }
  }

  &.-lowerpageTermTtl {
    position: relative;
    @include font-zmgo("bd");
    @include fontSize(3, 2.5);
    
    &.-center {
      text-align: center;
    }
    
    &.-ls3 {
      letter-spacing: 3px;
    }
  }

  &.-recipeTermTtl {
    position: relative;
    @include font-zmgo("bd");
    @include fontSize(2.4, 2);
    letter-spacing: 0.02em;
    
    &.-center {
      text-align: center;
    }
  }

  //間に区切り線を挟むメイン＆サブのセット見出し
  &_setttl {
    text-align: center;
      
    &_main {
      &.-topics, &.-contact {
        @include font-zmgo("bd");
        @include fontSize(2.4, 2);
        color: color-purple02(1);
      }

      &.-harvest {
        @include font-zmgo("bd");
        @include fontSize(4, 3.3);
        color: color-black01(1);
      }

      &.-recipe, &.-project {
        @include font-zmgo("bd");
        @include fontSize(4, 3.3);
        color: color-black01(1);
        letter-spacing: 0.02em;
      }
    }

    &_sub {
      &.-topics, &.-contact {
        @include font-zomin("bd");
        @include fontSize(1.6, 1.4);
        color: color-black01(1);
      }

      &.-harvest {
        @include font-zmgo("bd");
        @include fontSize(1.8, 1.35);
        color: color-black01(1);
      }

      &.-recipe, &.-project {
        @include font-zmgo("bd");
        @include fontSize(1.8, 1.35);
        color: color-black01(1);
        letter-spacing: 0.02em;
      }
      
      &.-project {
        color: color-red02(1);
      }
      
      &.-marche {
        @include font-zomin("bd");
        @include fontSize(1.8, 1.35);
        color: color-black01(1);
        letter-spacing: 0.02em;
      }
      
    }
  }
}