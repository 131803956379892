///// 800px以上で横並び、800px未満で縦並び /////
.c-flex01 {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-space-between;
  @include flex-align-items-stretch;
  
  &.-rev {
    @include flex-direction-row;

    @include breakpoint-pc {
      @include flex-row-reverse;
    }
  }

  &_item {
    &.-box2 {
      width: 100%;
      box-sizing: border-box;

      @include breakpoint-pc {
        width: 50%;
      }
      
      &.-leftbox {
        padding: 0;
        
        @include breakpoint-pc {
          padding-right: 30px;
        }
      }
      
      &.-rightbox {
        padding: 0;
        
        @include breakpoint-pc {
          padding-left: 30px;
        }
      }
    }
  }
}


///// 1000px以上で横並び、1000px未満で縦並び /////
.c-flex02 {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-space-between;
  @include flex-align-items-stretch;
  
  &.-rev {
    @include flex-direction-row;

    @include breakpoint-contentinner {
      @include flex-row-reverse;
    }
  }

  &_item {
    &.-box2 {
      width: 100%;
      box-sizing: border-box;

      @include breakpoint-contentinner {
        width: 50%;
      }
      
      &.-leftbox {
        padding: 0;
        
        @include breakpoint-contentinner {
          padding-right: 30px;
        }
      }
      
      &.-rightbox {
        padding: 0;
        
        @include breakpoint-contentinner {
          padding-left: 30px;
        }
      }
    }
  }
}


///// 1000px以上で横並び、1000px未満で縦並び、区切り線が分離 /////
.c-flex03 {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-space-between;
  @include flex-align-items-stretch;
  
  &.-rev {
    @include flex-direction-row;

    @include breakpoint-contentinner {
      @include flex-row-reverse;
    }
  }

  &_item {
    display: block;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: color-wh(1);

    @include breakpoint-contenthalf {
      display: inline-block;
      width: calc( 50% - 20px );
      padding: 20px 0;
      color: color-black01(1);
      text-align: justify;
    }
    @include breakpoint-content {
      width: calc( 50% - 40px );
      padding: 40px 0;
    }
    
    //PC時、奇数番目のメニューの右側に区切り線を表示する
    &:nth-of-type(2n+1) {
      &:before {
        @include breakpoint-contenthalf {
          display: block;
          position: absolute;
          top: 20px;
          right: -20px;
          width: 1px;
          height: calc(100% - 20px - 20px);
          content: "";
          background-color: color-wh(1);
        }
        @include breakpoint-content {
          display: block;
          position: absolute;
          top: 40px;
          right: -40px;
          width: 1px;
          height: calc(100% - 40px - 40px);
          content: "";
          background-color: color-wh(1);
        }
      }
    }

    //スマホ時の下区切り線(itemInnerにborder-bottomを入れても、余白が作り出せないため、afterを使って実装する)
    &:after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      content: "";
      background-color: color-wh(1);

      @include breakpoint-contenthalf {
//        display: none;
      }
    }
    
    &:nth-of-type(1), &:nth-of-type(2) {
      &:after {
        @include breakpoint-contenthalf {
          display: none;
        }
      }
    }
  }
}