.p1-logo {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-row;
  @include flex-justify-flex-start;
  @include flex-align-items-center;
//  width: 225px;
  box-sizing: border-box;
  
  &.-spheader {
    width: auto;
    height: 26px;
    margin-top: 3px;
    
    img {
      width: auto;
      height: 100%;
    }
  }

  //PCヘッダーロゴ
  &.-pcheader {
    width: auto;
    height: 100%;
    

    img {
      width: auto;
      height: 30px;
      margin-top: 3px;

      @include breakpoint-pc {
        height: 34px;
      }
      @include breakpoint-contentinner {
        height: 38px;
      }
      @include breakpoint-mainboxinner {
        height: 42px;
      }
    }
  }

  //画像ロゴ
  &.-img {
    width: 75px;
    height: 85px;
    margin: 20px auto 0;
    text-align: center;

    @include breakpoint-sp {
      margin: 30px auto;
    }
  }

  img {
    width: 100%;
    height: auto;
  }
}
