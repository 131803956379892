.l-wrap {
  &.-front {
    & .l-header {
      display: none;

      &.js-isscroll {
        display: block;
      }
      &.js-noscroll {
        display: none;
      }
    }
  }
}

.l-header {
  display: block;
  width: 100%;
  height: $drawer-space-sp;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: 0 5%;
  background-color: color-black01(1);

  @include breakpoint-pc {
    height: $drawer-space-pc;
    padding: 0;
    box-sizing: border-box;
    background-color: color-white02(1);
  }


  &_inner {
    width: 100%;
    height: 100%;
    margin: 0 auto;

    @include breakpoint-pc {
      height: 0;
    }
    @include breakpoint-mainbox {
//      width: $mainboxinner; //1500px - 60px - 60px
    }
  }
  
  //ドロワーが開く時に、左下ロゴ＆右下バーガーメニューの後ろを通る際、背後にドロワー内コンテンツが見えてしまいちらつくのを避けるために目隠しとして配置
  &:before {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1100;  //ドロワーより手前、バーガーメニューなどよりは奥に、目隠しとして配置
    width: 100%;
    height: $drawer-space-sp;
    content: "";
    background-color: color-black01(0.9);
    
    @include breakpoint-pc {
      display: none;
    }
  }
}