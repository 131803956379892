.p0-topics {
  @include breakpoint-pc {
  }
  
  &_head {
    position: relative;
    box-sizing: border-box;
  }

  &_body {
    position: relative;
    box-sizing: border-box;
  }
  
  &_linklist {
    text-align: center;
  }


  ///// お知らせカテゴリ /////
  &_cat {
    text-align: center;
    
    &_en {
      @include font-akrobatExb();
      @include fontSize(3.4, 2.55); //3.4 * 0.75
      color: color-black01(1);
    }

    &_jp {
      @include font-zomin("bd");
      @include fontSize(1.6, 1.4);
      color: color-black01(1);
    }
  }


  ///// お知らせ一覧 /////
  &_list {
    &_article {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-stretch;
      position: relative;
      padding: 10px 40px 10px 0;
      border-bottom: 1px solid color-red05(1);
      box-sizing: border-box;

      @include breakpoint-pc {
        padding: 20px 50px 20px 20px;
      }
      @include breakpoint-content {
        padding: 40px 50px 40px 40px;
      }

      &:hover {
        .p0-topics_list_imgbox img, .p0-topics_list_txtbox {
          filter: alpha(opacity=70);
          opacity: .7;
          transition: all 0.3s ease-in-out;
        }
      }
    }
    
    &_imgbox {
      position: relative;
      overflow: hidden;
      width: 90px;
      background-image: url("../images/topics/icon_bg.png");
      background-position: center top;
      background-repeat: no-repeat;
      background-size: 100% auto;
      border-radius: 100vh;

      @include breakpoint-pc {
        width: 90px;
      }
      @include breakpoint-content {
        width: 120px;
      }
    }
    
    &_txtbox {
      flex: 1;
      position: relative;
      padding-top: 10px;
      padding-left: 15px;
      box-sizing: border-box;

      @include breakpoint-pc {
        padding-top: 10px;
        padding-left: 20px;
      }
      @include breakpoint-content {
        padding-top: 20px;
        padding-left: 25px;
      }
      
      &:after {
        display: block;
        position: absolute;
        top: 50%;
        right: -40px;
        transform: translateY(-50%);
        width: 26px;
        height: 12px;
        content: "";
        background-image: url("../images/common/icon/arrow_blink.gif");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &_dt {
      margin-bottom: 10px;
      box-sizing: border-box;
      @include font-zmgo("bd");
      font-size: 1.6rem;
      color: color-purple01(1);

      @include breakpoint-pc {
        font-size: 2rem;
      }
      @include breakpoint-content {
        font-size: 2.4rem;
      }
    }
  
    &_ttl {
      box-sizing: border-box;
      @include font-ntsan("m");
      font-size: 1.3rem;
      color: color-black01(1);
      @include lineHeight(1.4, 1.6);

      @include breakpoint-pc {
        font-size: 1.6rem;
      }
      @include breakpoint-content {
        font-size: 1.8rem;
      }
    }
  }


  ///// お知らせ記事 /////
  &_article {
    padding: 15px 0;
    box-sizing: border-box;

    @include breakpoint-pc {
      padding: 30px 0;
    }

    &_dt {
//      margin-bottom: 10px;
      box-sizing: border-box;
      @include font-zmgo("bd");
      font-size: 1.6rem;
      color: color-purple02(1);
      text-align: center;

      @include breakpoint-pc {
        font-size: 2rem;
      }
      @include breakpoint-content {
        font-size: 2.4rem;
      }
    }

    &_cat {
      text-align: center;
      
      & .-name {
        @include font-zmgo("bk");
        @include fontSize(1.6, 1.4);
        color: color-purple02(1);
      }
      & .-desc {
        @include font-zomin("bd");
        @include fontSize(1.4, 1.2);
        color: color-black01(1);
      }
    }

    &_ttl {
    }
    
    &_txt {
      @include font-ntsan("r");
      @include fontSize(1.6, 1.3);
      color: color-black01(1);
      text-align: justify;
      @include lineHeight(2.3, 2);

      h2 {
        margin-bottom: 15px;

        @include breakpoint-pc {
          margin-bottom: 30px;
        }

        &:nth-of-type(n+1) {
          margin-top: 35px;
          
          @include breakpoint-pc {
            margin-top: 70px;
          }
        }
        
        &.-ttl01 {
          margin-bottom: 15px;
  
          @include breakpoint-pc {
            margin-bottom: 30px;
          }
        }
        
        &.-ttl02 {
          margin-bottom: 10px;
  
          @include breakpoint-pc {
            margin-bottom: 15px;
          }
        }
      }

      p {
//        margin-bottom: 20px;

        @include breakpoint-pc {
//          margin-bottom: 40px;
        }
      }
    }
  }
  
  
  &_cats {
    $cats-max-width: 1240px;
    $item-width-pc: 120px;
    $item-width-sp: 90px;
    $item-gap-x-pc: 40px;
    $item-gap-y-pc: 20px;
    $item-gap-x-sp: 30px;
    $item-gap-y-sp: 15px;
    $itemset-width-pc: $item-width-pc + $item-width-pc + $item-gap-x-pc;  //280px
    $itemset-width-sp: $item-width-sp + $item-width-sp + $item-gap-x-sp;  //210px

    display: grid;
    justify-content: center;
    grid-template-columns: repeat(1, $itemset-width-sp);
    grid-auto-columns: $itemset-width-sp;
    grid-gap: $item-gap-y-sp $item-gap-x-sp;

    @include breakpoint-tb {
      grid-template-columns: repeat(2, $itemset-width-sp);

      &.gridcols1 {
        grid-template-columns: repeat(1, $itemset-width-sp);
      }
      &.gridcols2, &.gridcols3, &.gridcols4 {
        grid-template-columns: repeat(2, $itemset-width-sp);
      }
    }
    @include breakpoint-pc {
      grid-template-columns: repeat(2, $itemset-width-sp);

      &.gridcols1 {
        grid-template-columns: repeat(1, $itemset-width-sp);
      }
      &.gridcols2 {
        grid-template-columns: repeat(2, $itemset-width-sp);
      }
      &.gridcols3, &.gridcols4 {
        grid-template-columns: repeat(3, $itemset-width-sp);
      }
    }
    @include breakpoint-content {
      grid-template-columns: repeat(3, $itemset-width-pc);
      grid-auto-columns: $itemset-width-pc;
      grid-gap: $item-gap-y-pc $item-gap-x-pc;

      &.gridcols1 {
        grid-template-columns: repeat(1, $itemset-width-pc);
      }
      &.gridcols2 {
        grid-template-columns: repeat(2, $itemset-width-pc);
      }
      &.gridcols3, &.gridcols4 {
        grid-template-columns: repeat(3, $itemset-width-pc);
      }
    }
    @media all and (min-width: 1360px) {
      &.gridcols1 {
        grid-template-columns: repeat(1, $itemset-width-pc);
      }
      &.gridcols2 {
        grid-template-columns: repeat(2, $itemset-width-pc);
      }
      &.gridcols3 {
        grid-template-columns: repeat(3, $itemset-width-pc);
      }
      &.gridcols4 {
        grid-template-columns: repeat(4, $itemset-width-pc);
      }
    }


/* flexbox使用版
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include flex-align-items-center;
    gap: 0 $item-gap-x-sp;

    @include breakpoint-pc {
      gap: 0 $item-gap-x-sp;
    }
    @include breakpoint-content {
      gap: 0 $item-gap-x-pc;
    }
*/

    &_itemset {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-row;
      @include flex-justify-space-between;
      @include flex-align-items-center;
      gap: 0px $item-gap-x-sp;
      width: $itemset-width-sp;
//      margin-bottom: $item-gap-y-sp;

      @include breakpoint-pc {
        gap: 0px $item-gap-x-sp;
        width: $itemset-width-sp;
      }
      @include breakpoint-content {
        gap: 0px $item-gap-x-pc;
        width: $itemset-width-pc;
//        margin-bottom: $item-gap-y-pc;
      }
      
      &.-dummy {
        display: block;
        width: $itemset-width-sp;
        height: 0;
        gap: 0px $item-gap-x-sp;
//        margin-bottom: 0;
  
        @include breakpoint-pc {
          width: $itemset-width-sp;
//          margin-bottom: 0;
        }
        @include breakpoint-content {
          width: $itemset-width-pc;
//          margin-bottom: 0;
        }
      }
    }
    
    &_item {
      display: block;
      width: $item-width-sp;
      
      @include breakpoint-pc {
        width: $item-width-sp;
      }
      @include breakpoint-content {
        width: $item-width-pc;
      }

      &:hover, &:focus {
        .p0-topics_cats_item_img img, .p0-topics_cats_item_txt {
          filter:alpha(opacity=70);
          opacity:0.7;
        }
      }

      &_img {
        position: relative;
        width: 100%;
        height: auto;
        background-image: url("../images/topics/icon_bg.png");
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 100% auto;
        
        img {
          width: 100%;
          height: auto;
          filter:alpha(opacity=100);
          opacity:1;
          transition: all 0.3s ease-in-out;
        }
      }
      
      &_txt {
        @include font-ntsan("b");
        font-size: 1.2rem;
        color: color-black01(1);
        text-align: center;
        @include lineHeight(2.3, 2);
        filter:alpha(opacity=100);
        opacity:1;
        transition: all 0.3s ease-in-out;
      
        @include breakpoint-pc {
          font-size: 1.2rem;
        }
        @include breakpoint-content {
          font-size: 1.6rem;
        }
      }
    }
  }


  ///// トップページ /////
  &.-front, &.-relatedposts {
    .p0-topics {
      &_head {
        @include flexbox;
        @include flex-wrap-wrap;
        @include flex-direction-column;
        @include flex-justify-space-between;
        @include flex-align-items-center;
        width: 100%;
        margin-bottom: 20px;
        
        @include breakpoint-contenthalf {
          @include flex-wrap-nowrap;
          @include flex-direction-row;
        }
        @include breakpoint-pc {
          margin-bottom: 0;
        }
        
        &_item {
          width: 186px;
  
          &.-ttl {
            flex: 1;
            color: color-wh(1);
          }
        }
      }

      &_body {
        margin-bottom: 10px;
        border-bottom: 1px solid color-wh(1);
  
        @include breakpoint-contenthalf {
          border-bottom-style: none;
        }
        @include breakpoint-pc {
          margin-bottom: 60px;
        }
      }
  
      &_list {
        &_article {
          padding: 10px 40px 10px 0;
          border-bottom-style: none;
    
          @include breakpoint-pc {
            padding: 20px 50px 20px 20px;
          }
          @include breakpoint-content {
            padding: 40px 50px 40px 40px;
          }
          
          &:nth-of-type(2n) {
            padding-left: 0;
          }
          
          &:hover {
            .p0-topics_list_imgbox_inner {
              opacity: 0.7;

              img {
                opacity: 0;
              }
            }
          }
        }

        &_imgbox {
//          background-color: color-wh(1);
          border-radius: 50%;
          box-sizing: border-box;
          overflow: hidden;
          
          &_inner {
            @include flexbox;
            @include flex-justify-center;
            @include flex-align-items-center;
            width: 100%;
            height: 100%;
            background-color: color-wh(1);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 100% auto;
          }
        }
        
        &_dt {
          
        }
        
        &_ttl {
          color: color-wh(1);
        }
      }
    }
  }


  ///// 関連記事 /////
  &.-relatedposts {
    .p0-topics {
      &_head {        
        &_item {
          width: 240px;

          &.-dummy, &.-link {
            width: 186px;

            @include max-breakpoint-pc {
              width: 1px;
            }
          }
        }
      }

      &_body {
        margin-bottom: 0;
        border-bottom-color: color-brown01(1);
      }

      &_list {
        &_dt {
          color: color-brown01(1);
        }

        &_ttl {
          color: color-black01(1);
        }
      }
    }
    
    .c-flex03 {
      &_item {
        &:nth-of-type(2n+1) {
          &:before {
            @include breakpoint-contenthalf {
              background-color: color-brown01(1);
            }
            @include breakpoint-content {
              background-color: color-brown01(1);
            }
          }
        }
    
        &:after {
          background-color: color-brown01(1);
        }
      }
    }
  }
}