.p5-form {
  width: 100%;
  max-width: 1120px;
  margin: auto;

  //STEP
  &_step {
    position: relative;
    @include font-akrobatExb();
    @include fontSize(1.7, 1.3);
    
    &.-center {
      text-align: center;
    }
  }
  
  //必須マーク
  &_required {
    @include font-zmgo("bd");
    @include fontSize(1.8, 1.44);
    color: color-red02(1);
  }

  //入力項目のラベルと入力欄を入れるボックス
  &_box {
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid color-red05(1);
    box-sizing: border-box;
    
    &.-noborder {
      border-bottom-style: none;
    }
  }

  &_item {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;

    @include breakpoint-pc {
    }
    
    &.-agree {
      .mwform-checkbox-field.horizontal-item, a {
        @include flexbox;
        @include flex-justify-center;
        @include flex-align-items-center;
        @include flex-align-content-center;
        
        label {
          @include flexbox;
          @include flex-justify-center;
          @include flex-align-items-center;
          @include flex-align-content-center;
          font-weight: 500;
        }
      }
      
      .error {
        margin-bottom: -5px !important;
        text-align: center;
        
        @include breakpoint-pc {
          margin-bottom: -10px !important;
        }
      }
    }
  }
  
  &_inputgroup {
  }

  &_inputbox {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-justify-flex-start;
    @include flex-align-items-center;
    @include flex-align-content-center;
  }

  &_input, &_textarea {
    width: 100%;
    padding: 3px 5%;
    border: 1px solid transparent;
    box-sizing: border-box;
    background-color: color-wh(1);

    @include breakpoint-pc {
      padding: 8px 15px;
    }

    &:focus {
    }
  }

  &_input {
    &.-txt {
      width: 100%;
    }
    
    &.-zip {
      width: 210px;
    }

    &.-check {
      width: 18px;
      height: 18px;
      margin-left: 3px;
      vertical-align: 3px;
    }
  }
  
  &_textarea {
    width: 100%;
    height: 200px;
    line-height: 1.7;
  }

  & .-link {
    margin-bottom: 15px;

    & a {
      width: 100%;
      max-width: 270px;
      display: block;
      line-height: 1;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 500;
      padding: 8px 5%;
      color: color-wh(1);
      background: color-red01(1);
      border-radius: 100px;
//      @include font-gj(m);

      @include breakpoint-pc {
        width: 310px;
        max-width: 310px;
        padding: 10px 20px;
        font-size: 1.7rem;
      }
    }
  }
  
  &_zipsrch {
    margin-left: 15px;
  }
  
  &_checkboxes {
    &.-center {
      
    }
  }

  &_btn {
    input {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      opacity: 1;
    }
  }
}

.mwform-checkbox-field-text {
  padding-left: 5px;
  //@include font-ntsan("b");
  @include fontSize(1.6, 1.3);
  color: color-black01(1);
}
.mwform-checkbox-field {
  line-height: 1;
}

.horizontal-item {
  label {
    @include flexbox;
    @include flex-justify-flex-start;
    @include flex-align-items-center;
    @include flex-align-content-center;
  }
}

.mw_wp_form .error, .mw_wp_form .p5-form_item .error {
  display: block;
  margin: 10px 0 -15px;
  @include font-yugo("m");
  @include fontSize(1.5, 1.3);
  color: color-red02(1) !important;
  font-weight: 700;
  line-height: 1.45;

  @include breakpoint-pc {
    margin: 15px 0 -20px;
  }
}

.mw_wp_form_input {
  & .p5-form_state {
    &.-confirm, &.-complete {
      display: none;
    }
  }

  & .p5-form_item.-backbtn, & .p5-form_btn.-top {
    display: none;
  }
  & .p5-form_thx {
    display: none;
  }
}

.mw_wp_form_confirm {
  & .p5-form_state {
    &.-input, &.-complete {
      display: none;
    }
  }
  
  & .p5-form_inputgroup {
    @include flexbox;
    @include flex-justify-flex-start;
    @include flex-align-items-start;
    
    & .p5-form_inputbox {
      margin-left: 0;
      margin-right: 0;
    }
  }

  & .p5-form_zipsrch {
    display: none;
  }

  & .p5-form_box.-content {
    padding-bottom: 40px;
    border-bottom-style: none;
  }
  
  & .p5-form_box.-agree {
    display: none;
  }

  & .error {
    display: none;
  }

  & .p5-form_btn.-submit {
    margin-bottom: 10px;
    @include breakpoint-pc {
      margin-bottom: 20px;
    }
  }
}

.mw_wp_form_complete {
  & .p5-form_state {
    &.-input, &.-confirm {
      display: none;
    }
  }

  & .p5-form_btn.-back, & .p5-form_btn.-confirm, & .p5-form_btn.xzssxsaazaa-submit { display: none; }
}