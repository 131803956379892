.p3-imgsbox {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-column;
  @include flex-justify-space-between;
  @include flex-align-items-stretch;
  position: relative;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100vw;
  
  @include breakpoint-tb {
    @include flex-direction-row;
  }

  &.-rev {
    @include breakpoint-tb {
      @include flex-row-reverse;
    }
  }
  
  // u-mb系と違い、480pxで切り替えが起きる
  &.-mb60_20 {
    margin-bottom: 20px;

    @include breakpoint-tb {
      margin-bottom: 60px;
    }
  }
  &.-mb90_20 {
    margin-bottom: 20px;

    @include breakpoint-tb {
      margin-bottom: 90px;
    }
  }
  
  &_item {
    position: relative;
    box-sizing: border-box;

    &.-slim {
      width: 100%;

      @include breakpoint-tb {
        width: 35.3%;
      }
    }

    &.-wide {
      width: 100%;

      @include breakpoint-tb {
        width: 60%;
      }
    }

    &.-littleslim {
      width: 100%;

      @include breakpoint-tb {
        width: 38%;
      }
    }

    &.-littlewide {
      width: 100%;

      @include breakpoint-tb {
        width: 57%;
      }
    }
    
    &.-flexbox {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-row;
      @include flex-justify-space-between;
      @include flex-align-items-stretch;

      &.-rev {
        @include flex-row-reverse;
      }
    }
    
    &.-pt40_25 {
      padding-top: 25px;

      @include breakpoint-tb {
        padding-top: 40px;
      }
    }
    
    &.-pr0_5vw {
      padding-right: 5vw;
      box-sizing: border-box;

      @include breakpoint-tb {
        padding-right: 0;
      }
    }
    
    &.-pl0_5vw {
      padding-left: 5vw;
      box-sizing: border-box;

      @include breakpoint-tb {
        padding-left: 0;
      }
    }

    &_inner {
      &.-slim {
        position: relative;
        width: 43.5%;
  
        @include breakpoint-tb {
          width: 100%;
        }
      }
  
      &.-wide {
        position: relative;
        width: 56.5%;
  
        @include breakpoint-tb {
          width: 100%;
        }
      }
    }
  }
}