// svg size settings
@mixin svgSize($svg_size_w, $svg_size_h) {
  width: $svg_size_w + px;
  height: $svg_size_h + px;
}


.icon-arw {
  @include flexbox;
  @include flex-justify-flex-start;
  @include flex-align-items-center;

  &:before {
    display: inline-block;
    content: "";
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
  }
  
  &.-wh {
    &:before {
      background-image: url("../images/common/icon/arw_r_wh.png");
    }
  }
  
  &.-size40 {
    width: 40px;
    height: 13px;
  }
  
  &.-size62 {
    width: 62px;
    height: 20px;
  }
}


.icon {
  display: inline-block;

  ///// メニュー /////
  &.-top {
    @include svgSize(20, 20);
    /*
    fill: color-wh(1);

    @include breakpoint-pc {
      fill: color-black01(1);
    }
    */
  }
  &.-topics {
    @include svgSize(23, 20);
    fill: color-wh(1);

    @include breakpoint-pc {
      fill: color-black01(1);
    }
  }
  &.-project {
    @include svgSize(22, 20);
    fill: color-wh(1);

    @include breakpoint-pc {
      fill: color-black01(1);
    }
  }
  &.-about {
    @include svgSize(24, 22);
    fill: color-wh(1);

    @include breakpoint-pc {
      fill: color-black01(1);
    }
  }
  &.-harvest {
    @include svgSize(21, 21);
    fill: color-wh(1);

    @include breakpoint-pc {
      @include svgSize(22, 22);
      fill: color-black01(1);
    }
  }
  &.-recipe {
    @include svgSize(23, 21);
    fill: color-wh(1);

    @include breakpoint-pc {
      fill: color-black01(1);
    }
  }
  &.-column {
    @include svgSize(22, 20);
    fill: color-wh(1);

    @include breakpoint-pc {
      fill: color-black01(1);
    }
  }
  &.-contact {
    @include svgSize(23, 18);
    fill: color-wh(1);

    @include breakpoint-pc {
      fill: color-black01(1);
    }
  }

  ///// ヘッダー /////
  &.-equip {
    @include svgSize(29, 26);
//    @include svgSize(19.5, 17.5);
    fill: color-white02(1);

    @include breakpoint-pc {
      @include svgSize(36.8, 32.8); // 46 * 0.8, 41 * 0.8
      fill: color-black01(1);
    }
    @include breakpoint-contentinner {
      @include svgSize(41.4, 36.9); // 46 * 0.9, 41 * 0.9
    }
    @include breakpoint-mainboxinner {
      @include svgSize(46, 41);
    }
  }

  ///// フッター /////
  &.-address {
    @include svgSize(10.5, 14);
    fill: color-wh(1);

    @include breakpoint-pc {
      @include svgSize(12, 16); //15px * 0.8, 20px * 0.8
    }
    @include breakpoint-contentinner {
      @include svgSize(15, 20);
    }
  }
  
  ///// トップページ /////
  &.-ig {
    display: block;
    width: 52px;

    @include breakpoint-pc {
      width: 70px;
    }
    
    a {
      display: block;
    }
  }
  
  ///// お知らせページ /////
  &.-topicsKv {
    @include svgSize(46.4, 40.8); //58px * 0.8, 51px * 0.8
    fill: color-black01(1);

    @include breakpoint-pc {
      @include svgSize(58, 51);
    }
  }
  
  ///// プロジェクトページ /////
  &.-projectKv {
    @include svgSize(44.8, 40.8); //56px * 0.8, 51px * 0.8
    fill: color-black01(1);

    @include breakpoint-pc {
      @include svgSize(56, 51);
    }
  }

  ///// 野菜図鑑ページ /////
  &.-harvestKv {
    @include svgSize(52.8, 52); //66px * 0.8, 65px * 0.8
    fill: color-black01(1);

    @include breakpoint-pc {
      @include svgSize(66, 65);
    }
  }
  
  ///// お野菜レシピページ /////
  &.-recipeKv {
    @include svgSize(48.8, 44); //61px * 0.8, 55px * 0.8
    fill: color-black01(1);

    @include breakpoint-pc {
      @include svgSize(61, 55);
    }
  }
  
  ///// お問い合わせページ /////
  &.-contactKv {
    @include svgSize(41.6, 32.8); //52px * 0.8, 41px * 0.8
    fill: color-black01(1);

    @include breakpoint-pc {
      @include svgSize(52, 41);
    }
  }

  ///// エラーページ /////
  &.-errorKv {
    @include svgSize(40.8, 40.8); //51px * 0.8
    fill: color-black01(1);

    @include breakpoint-pc {
      @include svgSize(51, 51);
    }
  }
}


///// レシピページ各種アイコン /////
.icon-recipe {
  display: block;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  &.-tag {
    width: 22px;
    height: 28px;
    background-image: url("../images/common/icon/tag.png");
  }

  &.-center {
    margin-left: auto;
    margin-right: auto;
  }
}
