.p0-hero {
  @include breakpoint-pc {
  }
  
  /* トップページ */
  &.-front {
    .p0-hero {
      &_bg {
        display: block;
        position: absolute;
        top: 0;
        left: 20%;
        transform: translateX(-50%);
        width: 1000px;
        height: 1590px;
        background-image: url("../images/front/kv/bg_sp.jpg");
        background-position: center top;
        background-repeat: repeat-x;
        background-size: auto 100%;
        
        @include breakpoint-sp {
          left: 25%;
        }
        @include breakpoint-tb {
          left: 40%;
          width: 800px;
          height: 1272px;
        }
        @include breakpoint-contenthalf {
          left: 50%;
        }
        @include breakpoint-pc {
          left: 50%;
          width: 100%;
          height: 2109px;
          background-image: url("../images/front/kv/bg_pc.jpg");
        }

        &:before {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 44px;
          content: "";
          background-image: url("../images/common/deco/topline.png");
          background-position: center top 20px;
          background-repeat: repeat-x;
          background-size: auto 24px;
        }

        &:after {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 1590px;
          content: "";
          background-image: url("../images/front/kv/bg_filter_sp.png");
          background-position: center top;
          background-repeat: repeat-x;
          background-size: auto 100%;

          @include breakpoint-tb {
            width: 100%;
            height: 1272px;
          }
          @include breakpoint-pc {
            height: 3057px;
            background-image: url("../images/front/kv/bg_filter_pc.png");
          }
        }
        
        //スマホ時のみ、黒半透明のフィルターを追加で敷く
        &_filter {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: "";
          background-color: color-bk(0.15);

          @include breakpoint-tb {
            display: none;
          }
        }
      }
      
      &_content {
        position: relative;
        padding-bottom: 120px; //120px / 750px * 100
//        padding-bottom: 16vw; //120px / 750px * 100
        box-sizing: border-box;

        @include breakpoint-pc {
          padding-bottom: 770px;
        }
      }
      
      &_logo {
        width: 260px; //ドロップシャドウ込みの画像サイズを指定する必要があるので注意
        height: 291px; //ドロップシャドウ込みの画像サイズを指定する必要があるので注意
        margin: 55px auto 0;

        @include breakpoint-pc {
          width: 418px; //ドロップシャドウ込みの画像サイズを指定する必要があるので注意
          height: 465px; //ドロップシャドウ込みの画像サイズを指定する必要があるので注意
          margin-top: 40px;
          margin-bottom: 10px;
        }
      }
      
      &_cname {
        position: absolute;
        z-index: 0;
        bottom: -60px;
        left: -5vw;
        transform: translateX(0.5vw);
        width: 100%;
        opacity: .4;

        @include breakpoint-pc {
          bottom: -120px;
        }
        @media all and (min-width: 1360px) {  //1240px + 60px + 60px
          left: calc(( 100vw - 1240px ) / -2);
        }

        svg {
          width: 95.4666666666667vw;  //716px / 750px * 100
          height: 22.5333333333333vw;  //169px / 750px * 100
          
          @include breakpoint-pc {
            width: 91.3vw;  //913px / 1000px * 100
            height: 21.5vw;  //215px / 1000px * 100
          }
          @include breakpoint-contentinner {
            @include svgSize(913, 215); //svgSize(SVGの幅 , SVGの高さ)
          }
        }
      }
      
      &_catchcopy {
        position: relative;
        margin-bottom: 60px;

        @include breakpoint-pc {
          margin-bottom: 70px;
        }

        &_ttl {
          position: relative;
          z-index: 1;
          margin-bottom: 15px;
          @include font-dnpsnsmin();
          font-size: 8vw; //30px / 375px * 100
          color: color-wh(1);
          text-align: center;
          @include lineHeight(1.5, 1.3);
          letter-spacing: -0.1em;

          @include breakpoint-sp {
            font-size: 3rem;
          }
          @include breakpoint-tb {
            letter-spacing: 0;
          }
          @include breakpoint-pc {
            font-size: 5.6vw; //56px / 1000px * 100
            margin-bottom: 20px;
            text-align: center;
          }
          @include breakpoint-contentinner {
            font-size: 5.6rem;
          }
        }
        
        &_txt {
          position: relative;
          z-index: 1;
          @include font-zomin("bd");
          font-size: 1.3rem;
          color: color-wh(1);
          text-align: justify;
          line-height: 2;

          @include breakpoint-sp {
            font-size: 1.4rem;
          }
          @include breakpoint-pc {
            @include font-zomin("r");
            font-size: 1.8rem;
            text-align: center;
          }
        }
      }
    }
/*
    .p0-hero_bg {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 2109px;
      background-image: url("../images/front/kv/bg_pc.jpg");
      background-position: center top;
      background-repeat: repeat-x;
      background-size: auto 100%;
    }

    .p0-hero_cname {
      svg {
        @include svgSize(913, 215); //svgSize(SVGの幅 , SVGの高さ)
      }
    }
*/
  }


  ///// 下層ページ /////
  &.-lowerpage {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-column;
    @include flex-justify-flex-start;
    @include flex-align-items-stretch;
    position: relative;
    height: 400px;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint-sp {
//      height: 50vw;
    }
    @include breakpoint-pc {
      height: 520px;
    }
    
    &:before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 44px;
      content: "";
      background-image: url("../images/common/deco/topline.png");
      background-position: center top 20px;
      background-repeat: repeat-x;
      background-size: auto 24px;
    }

    .p0-hero {
      &_logo {
        display: block;
        width: 150px; //ドロップシャドウ込みの画像サイズを指定する必要があるので注意
        height: 163px; //ドロップシャドウ込みの画像サイズを指定する必要があるので注意
        margin: 50px auto 20px;

        @include breakpoint-pc {
          width: 198px; //ドロップシャドウ込みの画像サイズを指定する必要があるので注意
          height: 215px; //ドロップシャドウ込みの画像サイズを指定する必要があるので注意
          margin: 50px auto 20px;
        }
        
        &:hover {
          filter: alpha(opacity=100);
          opacity: 1;
          
          img {
            pointer-events: none;
          }
        }
      }
      
      &_headline {
        flex: 1;
        position: relative;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 182.716049382716vw; //( ( 1480px * 2) / 1620px ) * 100, (円の半径×2) / 全体幅 * 100(vw化)
        padding: 15px 0 0;
        box-sizing: border-box;
        background-image: url("../images/lowerpage/headline_bg.png");
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 100% auto;
        text-align: center;
        
        @include breakpoint-pc {
          padding: 30px 0 0;
        }
        @media all and (min-width: 2800px) {
          width: 100%;
          left: 0;
          transform: translateX(0);
          background-image: url("../images/lowerpage/headline_bg_oversize.png");
          background-position: center bottom;
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        &_link {
          display: inline-block;
          text-align: center;
        }
      }
      
      &_icon {
        text-align: center;
      }
    }

  }


  ///// お知らせ /////
  &.-topics {
    background-image: url("../images/topics/kv_pc.jpg");
  }


  ///// プロジェクト /////
  &.-project {
    background-image: url("../images/project/kv_pc.jpg");
  }

  &.-marche {
    background-image: url("../images/project/marche/kv_pc.jpg");
  }

  &.-noukatsu {
    background-image: url("../images/project/noukatsu/kv_pc.jpg");
  }

  ///// 野菜図鑑 /////
  &.-harvest {
    background-image: url("../images/harvest/kv_pc.jpg");
  }


  ///// お野菜レシピ /////
  &.-recipe {
    background-image: url("../images/recipe/kv_pc.jpg");
  }


  ///// お問い合わせ /////
  &.-contact {
    background-image: url("../images/contact/kv_pc.jpg");
  }


  ///// エラー /////
  &.-error {
    background-image: url("../images/error/kv_pc.jpg");
  }
}