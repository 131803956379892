.p0-listbox {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  @include breakpoint-pc {
  }
  
  &_inner {
    position: relative;
    left: 5vw;
    width: 100%;
    margin: 0 0 0 auto;
    padding-top: 10px;
    padding-bottom: 15px;
    box-sizing: border-box;
    background-color: color-red01(0.3);
  
    @include breakpoint-pc {
      left: auto;
      width: 95%;
      padding-top: 40px;
      padding-bottom: 30px;
    }
    @media all and (min-width: 1240px) {  //1120px + 60px + 60px
      width: calc( 1000px + ( 100% - 1000px ) / 2 );
    }
    @include breakpoint-mainbox {
      width: 1190px;
    }

    ul {
      margin: 0 15px 0 15px;

      @include breakpoint-pc {
        margin: 0 60px;
      }
    }
    
    &_ttl {
      position: relative;
      left: -5%;
      
      @include breakpoint-pc {
        left: -5%;
      }
      @media all and (min-width: 1240px) {  //1120px + 60px + 60px
        left: calc( ( 100% - 1000px ) * -1 );
      }
      @include breakpoint-mainbox {
        left: -190px;
      }
      
      
    }
  }
}