.p3-img {
  img {
    width: 100%;
    height: auto;
  }
  
  ///// ロゴマーク /////
  //トップページアウトライン上部
  &.-l01 {
    width: 214px; //周囲にテキストあり
    margin: 0 auto;

    @include breakpoint-pc {
      width: 176px; //周囲にテキストなし、ロゴマークのみ
    }
  }

  //トップページアウトライン中程、カラーロゴ
  &.-l02 {
    width: 270px;
    margin: 6px auto 8px;

    @include breakpoint-tb {
      width: 54.1333333333333vw;
      margin-top: 25px;
      margin-bottom: 25px;
      margin-left: 20px;
    }
    @include breakpoint-pc {
      width: 32.9838709677419vw;  //409px / 1240px * 100 = 32.9838709677419vw
      margin-top: 0;
      margin-bottom: 40px;
      margin-left: 0;
    }
    @include breakpoint-mainboxinner {
      width: 409px;
    }
  }
  
  ///// プロジェクト /////
  &.-projectthm {
    border-radius: 50px / $contentinner * 100vw;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @include breakpoint-pc {
      @include imgRadius(80px, $contentinner);
    }
  }
  
  &.-projectlead01 {
    width: 100%;
    text-align: center;
    
    img {
      width: 100%;
      max-width: 720px;
      height: auto;
    }
  }
  
  &.-projectgallerythm {
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    
    img {
      width: 100%;
      height: auto;
    }
  }
  &.-projectgalleryimg {
    
  }


  ///// 農活倶楽部 /////
  &.-noukatsulogo01 {
    width: 100%;
    
    img {
      width: 100%;
//      max-width: 368px; //460px * 0.8 = 368
      height: auto;

      @include breakpoint-pc {
//        max-width: 460px;
      }
    }
  }

  &.-noukatsutxt01 {
    width: 100%;
    text-align: center;

    img {
      width: 88.6%;  //408px / 460px * 100% = 88.695652173913043%
      height: auto;
    }
  }

  &.-noukatsutxt02 {
    width: 100%;
    text-align: center;
    
    img {
      width: 81.9%;  //377px / 460px * 100% = 81.956521739130435%
      height: auto;
    }
  }
  
  &.-noukatsunote {
    width: 100%;
    
    img {
      width: 100%;
      height: auto;
    }
  }
  
  &.-noukatsunotetxt {
    width: 100%;
    
    img {
      width: 100%;
      height: auto;
    }
  }
}


///// ギャラリー表示制御用 /////
.js-gallerythm {
  opacity: 0;

  &.-hidden {
    display: none;
    opacity: 0;
  }

  &.-display {
    display: block;
    animation: 0.3s ease-out 0s 1 both fadeIn;
  }
}
