.p0-recipe {
  ///// レシピ種類丸タグ /////  
  $recipe-cats-item-gap-x-pc: 6px;
  $recipe-cats-item-gap-x-sp: 6px;

  ///// レシピ種類角タグ /////  
  $recipe-tags-item-gap-x-pc: 10px;
  $recipe-tags-item-gap-x-sp: 10px;
  
  &_type_tags {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include flex-align-items-center;
    gap: 0 $recipe-cats-item-gap-x-sp;
    @include font-zmgo("m");
    @include fontSize(1.6, 1.4);

    @include breakpoint-pc {
      gap: 0 $recipe-cats-item-gap-x-pc;
    }
    
    &.-left {
      @include flex-justify-flex-start;
    }

    &.-tag {
      gap: 0 $recipe-tags-item-gap-x-sp;

      @include breakpoint-pc {
        gap: 0 $recipe-tags-item-gap-x-pc;
      }
    }

    &_item {
      display: block;
      margin-bottom: 8px;
      box-sizing: border-box;
      color: color-black01(1);

      &.-cat {
        padding: 7px 13px 11px;
        border-radius: 100vh;
        color: color-wh(1);
        background-color: color-brown01(1);
      }

      &.-tag {
        padding: 4px 10px 8px;
        background-color: color-brown02(1);
        @include font-ntsan("m");
      }
    }
  }


  ///// レシピ一覧 /////
  $recipe-list-item-gap-x-pc: 30px;
  $recipe-list-item-gap-x-sp: 20px;
  $recipe-list-item-gap-y-pc: 30px;
  $recipe-list-item-gap-y-sp: 20px;

  &_list {
    display: grid;
    justify-content: start;
    grid-template-columns: 1fr 1fr;
    grid-gap: $recipe-list-item-gap-y-sp $recipe-list-item-gap-x-sp;

    @include breakpoint-tb {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include breakpoint-pc {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: $recipe-list-item-gap-y-pc $recipe-list-item-gap-x-pc;
    }
  }

  &_relatedlist {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include flex-align-items-stretch;
    gap: $recipe-list-item-gap-y-sp $recipe-list-item-gap-x-sp;
    @include font-zmgo("m");
    @include fontSize(1.6, 1.4);

    @include breakpoint-pc {
      gap: $recipe-list-item-gap-y-pc $recipe-list-item-gap-x-pc;
    }
  }

  &_list, &_relatedlist {
    &_item {
      display: block;
      
      &_imgbox {
        position: relative;
        overflow: hidden;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        
        img {
          display: block;
        }
      }
      
      &_txtbox {
        padding-top: 7px;
        box-sizing: border-box;
        
        @include breakpoint-pc {
          padding-top: 14px;
        }
      }
    }
  }

  &_relatedlist {
    &_item {
      width: calc( 50% - #{$recipe-list-item-gap-x-pc / 2} );

      @include breakpoint-tb {
        width: calc( 100% / 3 - #{$recipe-list-item-gap-x-pc * 2 / 3} );
      }
      @include breakpoint-pc {
        width: calc( 25% - #{$recipe-list-item-gap-x-pc * 3 / 4} );
      }
    }
  }

  ///// レシピ詳細 /////
  &_article {
    &_img {
      width: 100%;
      max-width: 720px;
      margin-left: auto;
      margin-right: auto;
    }
    
    &_veges {
      span, a {
//        &:after {
//          content: "/";
//        }
        
//        &:last-of-type:after {
//          content: "";
//        }
      }
    }
    
    &_txt {
      p {
        @include breakpoint-pc {
        }
      }
    }
    
    &_cookings {
      counter-reset: item;
      list-style-type: none;
      padding-left: 0;
      
      li {
        @include flexbox;
        @include flex-wrap-nowrap;
        @include flex-direction-row;
        @include flex-justify-flex-start;
        @include flex-align-items-start;
        position: relative;
        margin-bottom: 10px;

        @include breakpoint-pc {
          margin-bottom: 20px;
        }
        
        &:last-of-type {
          margin-bottom: 0;
          
          @include breakpoint-pc {
            margin-bottom: 0;
          }
        }

        &:before {
          display: inline-block;
          @include flex-shrink-0;
          transform: translateY(-0.2em);
          padding-right: 20px;
          box-sizing: border-box;
          counter-increment: item;
          content: counter(item);
          @include font-coquette("b");
          @include fontSize(4.8, 3.9);
          line-height: 1;
          color: color-green04(1);
          
          @include breakpoint-pc {
            transform: translateY(-0.19em);
            padding-right: 30px;
          }
        }
      }
    }

    &_author {      
      &_img {
        width: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  
  &_box {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include flex-align-items-stretch;
    gap: 0 0;
    box-sizing: border-box;
    
    @include breakpoint-pc {
      @include flex-wrap-nowrap;
      gap: 0 60px;
    }
    
    &.-ingredients {
      padding: 40px 0;
      
      @include breakpoint-pc {
        padding: 45px 0;
      }
    }
    
    &.-cooking {
      padding: 40px 0 25px;
      
      @include breakpoint-pc {
        padding: 70px 0 60px;
      }
    }
    
    &.-advice {
      padding: 40px 0 20px;
      
      @include breakpoint-pc {
        padding: 70px 0 60px;
      }
    }
    
    &.-author {
      margin-bottom: 40px;
      
      @include breakpoint-pc {
        margin-bottom: 75px;
      }
    }
    
    &.-info {
      gap: 20px 0;
      padding: 20px 30px 30px;
      border-radius: 5px;
      background-color: color-brown02(1);
      
      @include breakpoint-pc {
        gap: 0 60px;
        padding: 20px 0;
      }
      
      .p0-recipe_box_item.-head {
        padding-bottom: 15px;
        border-bottom: 1px solid color-bk(1);
        box-sizing: border-box;

        @include breakpoint-pc {
          padding-bottom: 0;
          border-bottom-style: none;
          border-right: 1px solid color-bk(1);
        }
      }

      .p0-recipe_box_item.-body {
        box-sizing: border-box;

        @include breakpoint-pc {
          padding-right: 60px;
          padding-bottom: 10px;
        }
        
        a {
          color: color-black01(1);
          
          &:hover {
            @extend %hover;
          }
        }
      }
    }

    &_item {
      width: 100%;
      
      &.-head {
        @include flexbox;
        @include flex-wrap-wrap;
        @include flex-direction-column;
        @include flex-justify-center;
        @include flex-align-items-center;
        @include flex-shrink-0;
        width: 100%;

        @include breakpoint-pc {
          width: 30%;
          min-width: 200px;
        }
        
        &.-top {
          @include flex-justify-flex-start;
        }
        
        &.-author {
          margin-bottom: 25px;
          
          @include breakpoint-pc {
            margin-bottom: 0;
          }
        }
      }
      
      &.-body {
        width: 100%;
        box-sizing: border-box;

        @include breakpoint-pc {
          width: calc(70% - 60px);
        }
        
        a {
          text-decoration: underline;
        }
      }
    }
  }

  &_ttlicon {
    text-align: center;

    &.-ingredients {
      img {
        width: auto;
        height: 17px;

        @include breakpoint-pc {
          height: 28px;
        }
      }
    }

    &.-cooking {
      img {
        width: auto;
        height: 16px;

        @include breakpoint-pc {
          height: 26px;
        }
      }
    }

    &.-advice {
      img {
        width: auto;
        height: 12px;

        @include breakpoint-pc {
          height: 20px;
        }
      }
    }

    &.-author {
      img {
        width: auto;
        height: 16px;

        @include breakpoint-pc {
          height: 25px;
        }
      }
    }
    
    &.-tag {
      img {
        width: auto;
        height: 18px;

        @include breakpoint-pc {
          height: 28px;
        }
      }
    }
  }
}