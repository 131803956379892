.p1-modal {
  width: 90%;
  max-width: 660px;
  height: 90%;
  max-height: 160px;
  border-radius: 50px;
  box-sizing: border-box;
  background-color: color-white01(1);

  @include breakpoint-tb {
    max-height: 200px;
  }
  @include breakpoint-pc {
    max-height: 240px;
  }

  //診療時間モーダルウィンドウ
  &.-oh {
    //中身の表は、「_ohtbl.scss」に記述
  }
  
  //電話するモーダルウィンドウ
  &.-tel {
    .p1-modal_telnum {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-center;
      @include flex-align-items-center;
      margin-bottom: 10px;
      padding: 10px 13px;
      border-bottom: 2px solid color-black01(1);
      box-sizing: border-box;
//      @include font-lato("b");
      font-size: 2.46rem; // 4.1 * 0.6
      color: color-black01(1);
      
      @include breakpoint-tb {
        padding: 10px 18px;
        font-size: 3.28rem; // 4.1 * 0.8
      }
      @include breakpoint-pc {
        margin-bottom: 15px;
        padding: 15px 23px;
        font-size: 4.1rem;
      }

      img {
        width: 19px;  //32px * 0.6
        height: auto;
        margin-right: 2.5vw;  //20px / 800px

        @include breakpoint-tb {
          width: 25px;  //32px * 0.8
        }
        @include breakpoint-pc {
          width: 32px;
          margin-right: 20px;
        }
      }
    }
    
    .p1-modal_reception {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-row;
      @include flex-justify-center;
      @include flex-align-items-center;
//      //@include font-ntsan("m");
      @include fontSize(1.4, 1.4);
      color: color-black01(1);
      line-height: 1.7;

      p {
        padding: 0 5px;
        box-sizing: border-box;

        @include breakpoint-pc {
          padding: 0 10px;
        }
      }
    }
  }
  
  //閉じるボタン
  .remodal-close {
    position: absolute;
    top: -10px;
    left: auto;
    right: 0;
    transform: translateY(-100%);
    width: 28px;
    height: 24px;
    background-image: url("../images/common/header/modal_close.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    
    &:before {
      display: none;
    }

    &:focus {
      outline:0;
    }
  }
  
  &_inner {
    @include flexbox;
    @include flex-direction-column;
    @include flex-justify-center;
    @include flex-align-items-center;
    width: 100%;
    height: 100%;
  }
}


//モーダルオープン時の、モーダルの上下左右余白
.remodal-wrapper {
  padding: 30px 5% !important;
  
  @include breakpoint-max {
    padding: 30px 60px !important;
  }
}

//モーダルオープン時の、モーダル以外の全面背景色設定
.remodal-overlay {
  background-color: rgba(0, 0, 0, 0.4) !important;
}