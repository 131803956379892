$i: 5;
@for $value from 0 through 18 {
  .u-m#{ $value * $i } {
    margin: $i * $value + px!important;
  }
  .u-mtb#{ $value * $i } {
    margin-top: $i * $value + px!important;
    margin-bottom: $i * $value + px!important;
  }
  .u-mlr#{ $value * $i } {
    margin-left: $i * $value + px!important;
    margin-right: $i * $value + px!important;
  }
  .u-mt#{ $value * $i } {
    margin-top: $i * $value  + px!important;
  }
  .u-mb#{ $value * $i } {
    margin-bottom: $i * $value + px!important;
  }
  .u-ml#{ $value * $i } {
    margin-left: $i * $value + px!important;
  }
  .u-mr#{ $value * $i } {
    margin-right: $i * $value + px!important;
  }
  .u-p#{ $value * $i } {
    padding: $i * $value + px!important;
  }
  .u-pt#{ $value * $i } {
    padding-top: $i * $value + px!important;
  }
  .u-pb#{ $value * $i } {
    padding-bottom: $i * $value + px!important;
  }
  .u-pl#{ $value * $i } {
    padding-left: $i * $value + px!important;
  }
  .u-pr#{ $value * $i } {
    padding-right: $i * $value + px!important;
  }
}

.u-m-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.u-m-pcAuto-spLeft {
  margin-left: 0 !important;
  margin-right: auto !important;

  @include breakpoint-pc {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.u-m-pcAuto-spRight {
  margin-left: auto !important;
  margin-right: 0 !important;

  @include breakpoint-pc {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}


.u-m-right {
  margin-left: auto !important;
  margin-right: 0 !important;
}


.u-m-left {
  margin-left: 0 !important;
  margin-right: auto !important;
}


$j: 5;
@for $value from 1 through 36 {
  .u-mt {
    &#{ $value * $j }_half {
      margin-top: $value * $j / 2 + px!important;
      @include breakpoint-pc {
        margin-top: $value * $j + px!important;
      }
    }
  }
  .u-mb {
    &#{ $value * $j }_half {
      margin-bottom: $value * $j / 2 + px!important;
      @include breakpoint-pc {
        margin-bottom: $value * $j + px!important;
      }
    }
  }
  .u-pt {
    &#{ $value * $j }_half {
      padding-top: $value * $j / 2 + px!important;
      @include breakpoint-pc {
        padding-top: $value * $j + px!important;
      }
    }
  }
  .u-pb {
    &#{ $value * $j }_half {
      padding-bottom: $value * $j / 2 + px!important;
      @include breakpoint-pc {
        padding-bottom: $value * $j + px!important;
      }
    }
  }
}

$k: 15;
@for $value from 1 through 18 {
  .u-mb {
    &#{ $value * $k }_23rd {
      margin-bottom: $value * $k * 2 / 3 + px!important;
      @include breakpoint-pc {
        margin-bottom: $value * $k + px!important;
      }
    }
  }
}

$l: 5;
@for $value from 1 through 36 {
  .u-mb {
    &#{ $value * $l }_level {
      margin-bottom: $value * $l / 3 + px!important;
      @include breakpoint-contenthalf {
        margin-bottom: $value * $l / 2 + px!important;
      }
      @include breakpoint-pc {
        margin-bottom: $value * $l + px!important;
      }
    }
  }
}

$j: 5;
@for $value from 1 through 36 {
  .u-mb-minus {
    &#{ $value * $j }_half {
      margin-bottom: - $value * $j / 2 + px!important;
      @include breakpoint-pc {
        margin-bottom: - $value * $j + px!important;
      }
    }
  }
}

///// 個別指定 /////
@mixin uMb($pc, $sp) {
  margin-bottom: $sp + px !important;

  @include breakpoint-pc {
    margin-bottom: $pc + px !important;
  }
}

.u-mb0_5 {
  @include uMb(0, 5);
}
.u-mb0_20 {
  @include uMb(0, 20);
}
.u-mb0_25 {
  @include uMb(0, 25);
}
.u-mb0_45 {
  @include uMb(0, 45);
}
.u-mb5_0 {
  @include uMb(5, 0);
}
.u-mb20_15 {
  @include uMb(20, 15);
}
.u-mb30_20 {
  @include uMb(30, 20);
}
.u-mb30_25 {
  @include uMb(30, 25);
}
.u-mb40_5 {
  @include uMb(40, 5);
}
.u-mb70_20 {
  @include uMb(70, 20);
}
.u-mb80_20 {
  @include uMb(80, 20);
}
.u-mb120_25 {
  @include uMb(120, 25);
}
.u-mb140_20 {
  @include uMb(140, 20);
}

//800px、480pxの3段階で切り替え
.u-mb80_20_0 {
  margin-bottom: 0 !important;

  @include breakpoint-tb {
    margin-bottom: 20px !important;
  }
  @include breakpoint-pc {
    margin-bottom: 80px !important;
  }
}

.u-mb_projectlist_nameen {
  margin-bottom: 5px !important;

  @include breakpoint-pc {
    margin-bottom: 0.892857142857143vw !important; //10px / 1120px * 100
  }
  @include breakpoint-content {
    margin-bottom: 10px !important;
  }
}
.u-mb_projectlist_namejp {
  margin-bottom: 12px !important;

  @include breakpoint-pc {
    margin-bottom: 2.232142857142857vw !important; //25px / 1120px * 100
  }
  @include breakpoint-content {
    margin-bottom: 25px !important;
  }
}
.u-mb_projectlist_txt {
  margin-bottom: 17.5px !important;

  @include breakpoint-pc {
    margin-bottom: 3.125vw !important; //35px / 1120px * 100
  }
  @include breakpoint-content {
    margin-bottom: 35px !important;
  }
}