.l-footer {
  background: color-black01(1);
  background-image: url("../images/common/deco/btmline.png");
  background-position: center bottom (15px + $drawer-space-sp); // 15px + (メニューの高さ) = 85px
//  background-position: center bottom 85px; // 15px + 70px(メニューの高さ) = 85px
  background-repeat: repeat-x;
  background-size: auto 24px;
  padding: 25px 0 (50px + $drawer-space-sp) 0;
//  padding: 20px 0 120px 0;

  @include breakpoint-pc {
    padding: 30px 0 (50px + $drawer-space-pc); // 50px + (メニューの高さ) = 120px
//    padding: 30px 0 120px; // 50px + 70px(メニューの高さ) = 120px
    background-position: center bottom (15px + $drawer-space-pc); // 15px + 70px(メニューの高さ) = 85px
//    background-position: center bottom 85px; // 15px + 70px(メニューの高さ) = 85px
  }
}