.p3-box {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-column;
  @include flex-justify-space-between;
  @include flex-align-items-stretch;
  
  @include breakpoint-pc {
    @include flex-direction-row;
  }

  &.-rev {
    @include breakpoint-pc {
      @include flex-row-reverse;
    }
  }
  
  &.-spimgonly {
    @include flex-direction-row;
    
    @include breakpoint-tb {
      display: none;
    }
    
    &.-rev {
      @include flex-row-reverse;
    
      @include breakpoint-tb {
        display: none;
      }
    }
  }
  
  &_item {
    position: relative;
  }


  &.-outline {
    .p3-box {
      &_item {
        box-sizing: border-box;
        
        &_img {
          width: 95vw;
          
          @include breakpoint-pc {
            width: 100%;
          }
          
          &.-plus20 {
            @include breakpoint-pc {
              width: calc( 100% + 20px );
            }
            
            &.-ajustleft {
              @include breakpoint-pc {
                margin-left: -20px;
              }
            }
          }

          &.-spleft {
            transform: translateX(-5vw);  //スマホ時、左に5vw寄せる

            @include breakpoint-pc {
              transform: translateX(0);
            }
          }
    
          &.-pc {
            display: none !important;
            
            @include breakpoint-pc {
              display: block !important;
            }
          }
      
          &.-sptb {
            display: block !important;
            
            @include breakpoint-pc {
              display: none !important;
            }
          }
        }

        &.-slim {
          width: 100%;
//background-color: red;

          @include breakpoint-pc {
            width: 27.4%;
          }
          
          &.-spimgonly {
            width: 27.4vw;

            &.-spleft {
              transform: translateX(-5vw);  //スマホ時、左に5vw寄せる
            }
            &.-spright {
              transform: translateX(5vw);  //スマホ時、右に5vw寄せる
            }
          }
        }

        &.-wide {
          width: 100%;
//background-color: green;

          @include breakpoint-pc {
            width: 56.5%;
          }
          
          &.-spimgonly {
            width: 56.5vw;

            &.-spleft {
              transform: translateX(-5vw);  //スマホ時、左に5vw寄せる
            }
            &.-spright {
              transform: translateX(5vw);  //スマホ時、右に5vw寄せる
            }
          }
        }
          
        &.-flexbox {
          @include flexbox;
        }
        
        &.-p80 {
          padding: 0;

          @include breakpoint-pc {
            padding: 80px 0;
          }
        }
        
        &.-pt80 {
          padding: 0;

          @include breakpoint-pc {
            padding: 80px 0 0 0;
          }
        }
        
        & .-txtleft {
          margin: 0 auto 0 0;
        }
        
        & .-txtright {
          margin: 0 auto 0 0;

          @include breakpoint-pc {
            margin: 0 0 0 auto;
          }
        }
      }
    }
  }
  
  ///// 480px - 800px時にのみ機能する画像ボックス /////
  &.-imgs {
    //常時表示するコンテンツ
    & .-all {
      display: block;
    }

    //480px-800px時のみ表示するコンテンツ
    & .-tbpc {
      display: none;

      @include breakpoint-tb {
        display: block;
      }
      @include breakpoint-pc {
        display: none;
      }
    }
  }
  
  ///// プロジェクト一覧ページのリストボックス /////
  &.-projectlist {
//    @include flex-align-items-center;
    @include flex-align-items-start;

    .p3-box {
      &_item {
        position: relative;

        &.-img {
          width: 100%;
//          margin-bottom: 20px;
          
          @include breakpoint-pc {
            width: 64.2%; //720px/ 1120px * 100(%) ≒ 64.2%
            margin-bottom: 0;
          }
          @include breakpoint-mainboxinner {
  //          width: 720px;
          }
        }
  
        &.-txt {
          overflow: hidden;
          width: 100%;
          border-radius: 50px / $contentinner * 100vw;
          border-top-left-radius: 0;
          border-top-right-radius: 0;

          @include breakpoint-pc {
            overflow: visible;
            width: calc(100% - 64.2%);
            border-radius: 0;
          }
        }
        
        &_inner {
          position: relative;
          width: 100%;
          padding: 4vw 5vw 5vw;
//          padding: 15px 20px 20px;
          box-sizing: border-box;
          background-color: color-wh(0.85);

          @include breakpoint-pc {
            position: absolute;
//            top: 50%;  //上下中央配置の場合は、この行を有効化する
            top: 8.035714285714286vw;  //90px / 1120px * 100
            right: 0;
//            transform: translate(0, -50%);  //上下中央配置の場合は、この行を有効化する
//            transform: translateX(-20%);  //100px / 500px * 100(%)
//            width: 500px;
            width: 125%;  //500px / 400px * 100(%) = 125%
            padding: 2.232142857142857vw 3.571428571428571vw 3.571428571428571vw;  //25px / 1120px * 100 = 2.678571428571429、40px / 1120px * 100 = 3.571428571428571
          }
          @include breakpoint-contentinner {
            top: 90px;
//            width: 500px;
//            transform: translateX(-20%);  //100px / 500px * 100(%)
            width: 125%;  //500px / 400px * 100(%) = 125%
          }
          @include breakpoint-content {
            padding: 25px 40px 40px;
          }
        }
      }
    }

    &.-rev {
      .p3-box_item_inner {
        @include breakpoint-pc {
          right: auto;
          left: 0;
        }
        @include breakpoint-contentinner {
        }
      }
    }
  }


  ///// マルシェページ、農活倶楽部ページのセクションボックス /////
  &.-secmarche, &.-secnoukatsu {
    &:nth-of-type(2n + 1) {
      background-color: color-brown02(1);
    }
    
    &:nth-of-type(2n) {
      background-color: color-wh(1);
    }
    
    &.-seclast {
      background-color: color-white01(1) !important;
    }
  }


  ///// マルシェページのギャラリーボックス /////
  &.-gallerylist {
    $gallery-list-item-gap-x-pc: 30px;
    $gallery-list-item-gap-x-sp: 20px;
    $gallery-list-item-gap-y-pc: 30px;
    $gallery-list-item-gap-y-sp: 20px;
  
    display: grid;
    justify-content: start;
    grid-template-columns: 1fr 1fr;
    grid-gap: $gallery-list-item-gap-y-sp $gallery-list-item-gap-x-sp;
    padding: 30px 0;
    box-sizing: border-box;

    @include breakpoint-tb {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include breakpoint-pc {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: $gallery-list-item-gap-y-pc $gallery-list-item-gap-x-pc;
      padding: 60px 0;
    }
  }


  ///// 農活倶楽部ページの農活倶楽部紹介ボックス /////
  &.-noukatsuIntro {
    padding: 25px 0 35px;
    box-sizing: border-box;
    background-color: color-yellow01(1);

    @include breakpoint-pc {
      padding: 50px 0 70px;
    }

    .p3-box {
      &_inner {
        $noukatsu-item-gap-x-co: 90px;
        $noukatsu-item-gap-x-pc: 8vw; //90px / 1120px * 100vw = 8.035714285714286
        $noukatsu-item-gap-x-sp: 8vw; //90px / 1120px * 100vw = 8.035714285714286
        $noukatsu-item-gap-y-co: 90px;
        $noukatsu-item-gap-y-pc: 8vw; //90px / 1120px * 100vw = 8.035714285714286
        $noukatsu-item-gap-y-sp: 30px;
      
        display: grid;
        justify-content: start;
        grid-template-columns: 1fr;
        grid-gap: $noukatsu-item-gap-y-sp $noukatsu-item-gap-x-sp;
        box-sizing: border-box;
    
        @include breakpoint-contenthalf {
          grid-template-columns: 1fr 1fr;
        }
        @include breakpoint-pc {
          grid-template-columns: 1fr 1fr;
          grid-gap: $noukatsu-item-gap-y-pc $noukatsu-item-gap-x-pc;
        }
        @include breakpoint-content {
          grid-gap: $noukatsu-item-gap-y-co $noukatsu-item-gap-x-co;
        }
      }
      
      &_item {
        &.-left {
          padding-top: 10px;
          box-sizing: border-box;

          @include breakpoint-pc {
            padding-top: 15px;
          }
        }
      }
    }
  }
  
  //見出しのロゴ＆テキスト画像ボックス
  &.-noukatsunotettlbox {
    width: 100%;
    max-width: 368px; //460px * 0.8 = 368
    margin-left: auto;
    margin-right: auto;

    @include breakpoint-pc {
      max-width: 460px;
    }
  }

  //「農業を気軽に楽しむクラブ活動」の枠
  &.-noukatsutxt02wrap {
    display: grid;
    justify-content: center;
    width: 100%;
    padding: 5px;
    border-radius: 100vh;
    box-sizing: border-box;
    text-align: center;
    background-color: color-green05(1);
  }
  
  //WEB SITE
  &.-noukatsuwebsite {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include flex-align-items-center;
    gap: 0 7px; //8px * 0.8 = 6.4
    
    @include breakpoint-pc {
      gap: 0 8px;
    }

    .p3-box {
      &_item {
        position: relative;

        &.-head {
          @include flexbox;
          @include flex-wrap-nowrap;
          @include flex-direction-row;
          @include flex-justify-flex-start;
          @include flex-align-items-center;
          gap: 0 7px; //8px * 0.8 = 6.4
          
          @include breakpoint-pc {
            gap: 0 8px;
          }

          &::after {
            display: block;
            position: relative;
            width: 13px;  //16px * 0.8 = 12.8
            height: 12px;  //14px * 0.8 = 11.2
            content: "";
            background-image: url("../images/common/icon/dbl_arw_right_black01.png");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
  
            @include breakpoint-pc {
              width: 16px;
              height: 14px;
            }
          }
        }
      }
    }
  }

  //イメージキャラクターのノート
  &.-noukatsunote {
    max-width: 360px; //450px * 0.8 = 360px
    margin-left: auto;
    margin-right: auto;
    
    @include breakpoint-pc {
      max-width: 450px;
    }

    .p3-box {
      &_item {
        position: relative;

        &.-imgbox {
          
        }
        
        &.-txtbox {
          margin-top: -5px;
          padding: 0 4.032258064516129vw 20px; //25px / 620px * 100vw、40px / 2 = 20px
          box-sizing: border-box;
          background-color: color-yellow02(1);

          @include breakpoint-contenthalf {
            padding: 0 2.232142857142857vw 20px; //25px / 1120px * 100vw、40px / 2 = 20px
          }
          @include breakpoint-pc {
            margin-top: -10px;
            padding: 0 25px 40px;
          }
        }
      }
    }
  }
  
  //追加画像・情報
  &.-noukatsuAddImgtxt {
    display: block;
    position: relative;
    width: 100%;
    
    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}