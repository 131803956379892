.p4-postarea {
  & img {
    display: block;
    width: 100%;
    height: auto;
  }
  
  & a {          
    &:hover {
      @extend %hover;
    }
  }
}