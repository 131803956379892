//メニュー項目数(TOPからお問い合わせまで、表示するメニュー項目数をここで設定)
//ここで設定したメニュー項目数に従い、メニュー最大幅や各メニュー幅を自動的に設定できるようになっている
//$menu-item-num: 7;
$menu-item-num: 6;


.p1-gnav {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-row;
  @include flex-justify-space-between;
  @include flex-align-items-center;
  overflow: visible;
  width: 100%;
  height: 100%;

  @include breakpoint-pc {
    height: $drawer-space-pc;
  }
  @include breakpoint-mainbox {
  }

  &_logo {
    display: block;
    position: relative;
    z-index: 1200;  //バーガーメニューと同じにする
    transform: translateX(-5px);
    box-sizing: border-box;

    @include breakpoint-pc {
      display: none;
    }
  }

  &_content {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-column;
    @include flex-justify-flex-start;
    @include flex-align-items-stretch;
    position: relative;
    width: 100%;
    padding: 0 5%;
    box-sizing: border-box;

    @include breakpoint-pc {
      @include flexbox;
//      @include flex-shrink-0;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-end;
      @include flex-align-items-stretch;
      position: relative;
      transform: translateY(0);
      width: 100%;
      max-width: 143px * $menu-item-num;  //_gnav.scssの最上部で設定したメニュー項目数に従って、幅を自動計算する
//      max-width: 429px;  //(140px(メニュー幅) + 3px(区切り線幅)) * 3項目、全3項目の時の最大幅
//      max-width: 572px;  //(140px(メニュー幅) + 3px(区切り線幅)) * 4項目、全4項目の時の最大幅
//      max-width: 715px;  //(140px(メニュー幅) + 3px(区切り線幅)) * 5項目、全5項目の時の最大幅
//      max-width: 858px;  //(140px(メニュー幅) + 3px(区切り線幅)) * 6項目、全6項目の時の最大幅
//      max-width: 1001px;  //(140px(メニュー幅) + 3px(区切り線幅)) * 7項目、全7項目の時の最大幅
      height: 100%;
      padding: 0;
      background-image: none;
      background-color: transparent;
    }
  }

  &_list {
    width: 100%;
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-space-between;
    margin-bottom: 0;
    box-sizing: border-box;

    @include breakpoint-sp {
      margin-bottom: 40px;
    }
    @include breakpoint-pc {
      @include flex-align-items-end;
      @include flex-align-content-end;
      width: 100%;
      height: 100%;
      margin-bottom: 0;
      padding: 0;
    }
    @include breakpoint-content {
    }
  }

  &_item {
    display: block;
    position: relative;
    width: calc( 50% - 20px );
    margin: 0;
    padding: 20px 0;
    box-sizing: border-box;
    color: color-wh(1);

    @include breakpoint-pc {
      display: inline-block;
      width: 100% / $menu-item-num; //_gnav.scssの最上部で設定したメニュー項目数に従って、幅を自動計算する
//      width: calc( 100% / 7 );//メニュー項目数が7個の場合
      padding: 0 0 0 3px;
      color: color-black01(1);
      text-align: right;
    }

    //スマホ時の下区切り線(itemInnerにborder-topを入れても、余白が作り出せないため、afterを使って実装する)
    &:after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      content: "";
      background-color: color-wh(1);

      @include breakpoint-pc {
        display: none;
      }
    }
    
    //スマホ時、奇数番目のメニューの右側に区切り線を表示する
    &:nth-of-type(2n+1) {
      &:before {
        display: block;
        position: absolute;
        top: 20px;
        right: -20px;
        width: 1px;
        height: calc(100% - 20px - 20px);
        content: "";
        background-color: color-wh(1);
  
        @include breakpoint-pc {
          display: none;
        }
      }
    }
    
    //スマホ時、PC用お問い合わせ(最後から1番目)＋スマホ時も表示するメニューの最後二つ(最後から2番目、3番目)の下線(after)を非表示にする
    &:nth-of-type(1), &:nth-of-type(2) {
      &:after {
        display: none;
      }
    }

    //ホバー時の色を設定
    &:nth-of-type(7n+1) {
      &:hover, &:focus {
        color: color-purple02(1);
        
        .p1-gnav_itemInner {
          color: color-purple02(1);
          border-bottom-color: color-purple02(1);
        }
      
        .icon {
          fill: color-purple02(1);
    
          @include breakpoint-pc {
            fill: color-purple02(1);
          }
        }
      }
    }
    &:nth-of-type(7n+2) {
      &:hover, &:focus {
        color: color-green01(1);
        
        .p1-gnav_itemInner {
          color: color-green01(1);
          border-bottom-color: color-green01(1);
        }
      
        .icon {
          fill: color-green01(1);
    
          @include breakpoint-pc {
            fill: color-green01(1);
          }
        }
      }
    }
    &:nth-of-type(7n+3) {
      &:hover, &:focus {
        color: color-red02(1);
        
        .p1-gnav_itemInner {
          color: color-red02(1);
          border-bottom-color: color-red02(1);
        }
      
        .icon {
          fill: color-red02(1);
    
          @include breakpoint-pc {
            fill: color-red02(1);
          }
        }
      }
    }
    &:nth-of-type(7n+4) {
      &:hover, &:focus {
        color: color-orange01(1);
        
        .p1-gnav_itemInner {
          color: color-orange01(1);
          border-bottom-color: color-orange01(1);
        }
      
        .icon {
          fill: color-orange01(1);
    
          @include breakpoint-pc {
            fill: color-orange01(1);
          }
        }
      }
    }
    &:nth-of-type(7n+5) {
      &:hover, &:focus {
        color: color-green02(1);
        
        .p1-gnav_itemInner {
          color: color-green02(1);
          border-bottom-color: color-green02(1);
        }
      
        .icon {
          fill: color-green02(1);
    
          @include breakpoint-pc {
            fill: color-green02(1);
          }
        }
      }
    }
    &:nth-of-type(7n+6) {
      &:hover, &:focus {
        color: color-red03(1);
        
        .p1-gnav_itemInner {
          color: color-red03(1);
          border-bottom-color: color-red03(1);
        }
      
        .icon {
          fill: color-red03(1);
    
          @include breakpoint-pc {
            fill: color-red03(1);
          }
        }
      }
    }
    &:nth-of-type(7n) {
      &:hover, &:focus {
        color: color-green03(1);
        
        .p1-gnav_itemInner {
          color: color-green03(1);
          border-bottom-color: color-green03(1);
        }
      
        .icon {
          fill: color-green03(1);
    
          @include breakpoint-pc {
            fill: color-green03(1);
          }
        }
      }
    }

    &.-pconly {
      @if $menu-item-num % 2 != 0 {
        display: none;
        
        @include breakpoint-pc {
          display: block;
        }
      } @else {
        .p1-gnav_itemInner {
          display: none;
          
          @include breakpoint-pc {
            display: block;
          }
        }
      }
    }
    
    &.-sponly {
      display: block;
      
      @include breakpoint-pc {
        display: none;
      }
    }
  }

  &_itemInner {
    @include flexbox;
    @include flex-direction-column;
    @include flex-justify-center;
    @include flex-align-items-start;
    position: relative;
    height: 100%;
    padding: 5px;
//    border-bottom: 1px solid color-wh(1);
//    border-right: 1px solid color-wh(1);
    box-sizing: border-box;
    color: inherit;
    line-height: 1;

    @include breakpoint-sp {
    }
    @include breakpoint-pc {
      padding: 9px 0 10px;
      border-right-style: none;
      border-bottom: 6px solid color-black01(1);
    }
        
    .icon {
      fill: color-wh(1);

      @include breakpoint-pc {
        fill: color-black01(1);
      }
    }
  
    &.is-current {
      color: color-red04(1);
      border-bottom-color: color-red04(1);
  
      @include breakpoint-pc {
        color: color-red01(1);
        border-bottom-color: color-red01(1);
      }

      .icon {
        fill: color-red04(1);
  
        @include breakpoint-pc {
          fill: color-red01(1);
        }
      }

      &:hover, &:focus {
        color: inherit;
      }
    }
  }

  &_itemIcon {
    width: 24px;
    height: 24px;
    margin: 0 auto 4px;
    text-align: center;
  }
  
  &_itemTxt {
    width: 100%;
    @include font-ntsan("m");
    font-size: 1.8rem;
    text-align: center;

    @include breakpoint-pc {
      font-size: 1.3rem;
    }
    @include breakpoint-contentinner {
      font-size: 1.5rem;
    }
    @include breakpoint-mainboxinner {
      font-size: 1.7rem;
    }
  }

  &_list.-btn {
    width: auto;
    margin-bottom: 15px;
    padding-top: 5px;

    @include breakpoint-sp {
      margin-bottom: 31px;
      padding-top: 15px;
    }
    @include breakpoint-pc {
      @include flex-wrap-nowrap;
      margin-left: 0;
      margin-right: 0;
      padding-top: 0;
    }
    @include breakpoint-content {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &_list.-btn &_item {
    position: relative;
    @extend %hover;
    width: 100%;
    max-width: 336px;
    margin: 0 auto;
    border-radius: 15px;
    background-image: url("../images/common/header/btn_reserve_bgc_sp.png");
    background-position: center center;
    background-size: auto 100%;
    background-repeat: repeat-x;

    @include breakpoint-pc {
      width: 130px;
      height: 100%;
      margin-right: 10px;
      background-image: none;
      border-radius: 0;
    }
    @include breakpoint-content {
      width: 161px;
    }
    
    &:before, &:after {
      display: block;
      position: absolute;
      top: 0;
      width: 15px;
      height: 100%;
      content: "";
      background-position: center center;
      background-size: auto 100%;
      background-repeat: no-repeat;
      @include breakpoint-pc {
        display: none;
      }
    }
    &:before, {
      left: 0;
      background-image: url("../images/common/header/btn_reserve_bgl_sp.png");
    }
    &:after {
      right: 0;
      background-image: url("../images/common/header/btn_reserve_bgr_sp.png");
    }

    &:nth-of-type(n) {
      &:after {
 //       display: none;
      }
    }

    & .p1-gnav_itemInner {
      @include flex-justify-center;
      position: relative;
      z-index: 1;

      &_img {
        width: auto;
        height: 28px;
        
        @include breakpoint-pc {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  
  ///// お問い合わせボタン(スマホ時のみ表示) /////
  &_contactbtn {
    margin-bottom: 15px;
    
    @include breakpoint-sp {
      margin-bottom: 30px;
    }
    @include breakpoint-pc {
      margin-bottom: 0;
    }
  }

  ///// SNS(スマホ時のみ表示) /////
  &_sns {
    @include flexbox;
    @include flex-direction-row;
    @include flex-justify-center;
    @include flex-align-items-center;
    text-align: center;

    & .-fb, & .-ig, & .-tw {
      display: inline-block;
      margin: 0 12px;

      img {
        width: 100%;
        height: auto;
      }
    }

    & .-fb {
      width: 18px;
      margin-left: 34px;  //FBアイコンの方が狭いので、中央寄せにするための位置調整

      @include breakpoint-pc {
        width: 20px;
      }
    }
    
    & .-ig {
      width: 36px;

      @include breakpoint-pc {
        width: 40px;
      }
    }
    
    & .-tw {
      width: 38px;
      margin-left: 10px;

      @include breakpoint-pc {
        width: 40px;
      }
    }
  }
}


.l-header {
  &.js-isscroll {
    .p1-gnav {

      @include breakpoint-pc {
      }
      
      .p1-gnav_list.-btn {
        @include breakpoint-pc {
        }
      }
    }
    
  }

  &.js-noscroll {
    .p1-gnav {
 
      @include breakpoint-pc {
      }
    }
  }
}
