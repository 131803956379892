//@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Zen+Kaku+Gothic+New:wght@400;500;700&family=Zen+Maru+Gothic:wght@400;500;700;900&family=Zen+Old+Mincho:wght@400;700;900&family=Noto+Sans+JP:wght@400;500;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Zen+Maru+Gothic:wght@400;500;700;900&family=Zen+Old+Mincho:wght@400;700;900&family=Noto+Sans+JP:wght@400;500;700&display=swap');

@mixin font-gothic() {
  font-family:"Helvetica Neue","Segoe UI","Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
}


@mixin font-yugo($status) {
  font-family:"游ゴシック体", "Yu Gothic", YuGothic, "Yu Gothic Medium", "游ゴシック Medium", "Lucida Grande","segoe UI", "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "Verdana", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-style: normal;
  font-weight: 500;

  @if $status == 'r' {  //Regular
    font-weight: 400;
  } @else if $status == 'm' { //Medium
    font-weight: 500;
  } @else if $status == 'bd' { //Bold
    font-weight: 700;
  }
}


///// Google Fonts /////
//Zen Kaku Gothic New
/*
@mixin font-zkgon($status) {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'r' {  //Regular
    font-weight: 400;
  } @else if $status == 'm' { //Medium
    font-weight: 500;
  } @else if $status == 'bd' { //Bold
    font-weight: 700;
  }
}
*/


//Zen Maru Gothic
@mixin font-zmgo($status) {
  font-family: 'Zen Maru Gothic', sans-serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'r' {  //Regular
    font-weight: 400;
  } @else if $status == 'm' { //Medium
    font-weight: 500;
  } @else if $status == 'bd' { //Bold
    font-weight: 700;
  } @else if $status == 'bk' {  //Black
    font-weight: 900;
  }
}


//Zen Old Mincho
@mixin font-zomin($status) {
  font-family: 'Zen Old Mincho', serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'r' {  //Regular
    font-weight: 400;
  } @else if $status == 'bd' {  //Bold
    font-weight: 700;
  } @else if $status == 'bk' {  //Black
    font-weight: 900;
  }
}


//Lato
@mixin font-lato($status) {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'l' {
    font-weight: 300;
  } @else if $status == 'r' {
    font-weight: 400;
  } @else if $status == 'b' {
    font-weight: 700;
  }
}

//源の角ゴシック | noto sans
@mixin font-ntsan($status) {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'r' {
    font-weight: 400;
  } @else if $status == 'm' { //Medium
    font-weight: 500;
  } @else if $status == 'b' {
    font-weight: 700;
  }
}


///// Adobe Fonts /////
//DNP 秀英にじみ初号明朝 Std
@mixin font-dnpsnsmin() {
  font-family: dnp-shuei-nshogomincho-std, sans-serif;
  font-weight: 700;
  font-style: normal;
}

//Coquette
@mixin font-coquette($status) {
  font-family: coquette,sans-serif;
  font-weight: 400;
  font-style: normal;
  
  @if $status == 'b' {
    font-weight: 700;
  } 
}


///// Web Font /////
//Akrobat Bold
@font-face {
  font-family: 'akrobatbold';
  src: url('../font/akrobat-bold-webfont.eot');
  src: url('../font/akrobat-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../font/akrobat-bold-webfont.woff2') format('woff2'),
       url('../font/akrobat-bold-webfont.woff') format('woff'),
       url('../font/akrobat-bold-webfont.svg#akrobatbold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@mixin font-akrobatBd() {
  font-family: 'akrobatbold';
  font-weight: normal;
  font-style: normal;
}


//Akrobat ExtraBold
@font-face {
  font-family: 'akrobatextrabold';
  src: url('../font/akrobat-extrabold-webfont.eot');
  src: url('../font/akrobat-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../font/akrobat-extrabold-webfont.woff2') format('woff2'),
       url('../font/akrobat-extrabold-webfont.woff') format('woff'),
       url('../font/akrobat-extrabold-webfont.svg#akrobatextrabold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@mixin font-akrobatExb() {
  font-family: 'akrobatextrabold';
  font-weight: normal;
  font-style: normal;
}
