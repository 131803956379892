/* トップページのみで使用するCSS */
.p0-front {
  ///// アウトライン /////
  &_outline {
    position: relative;
    margin-top: -135px;
//    top: -16vw; //120px / 750px * 100
    left: 50%;
    transform: translateX(-50%);
    width: 182.716049382716vw; //( ( 1480px * 2) / 1620px ) * 100, (円の半径×2) / 全体幅 * 100(vw化)
//    border-radius: 100vh;
//    box-sizing: border-box;
//    background-color: color-wh(1);
    @include breakpoint-pc {
      margin-top: calc( -770px - 10px );
    }
    
    ///// 円形の野菜スライドショーの下部を目隠しする /////
    &_blind {
      padding-top: 250px;
      box-sizing: border-box;

      @include breakpoint-pc {
        padding-top: 292px;
      }
      
      
      &:before {
        display: block;
        position: absolute;
        top: 70vw;
        left: 0;
        width: 100vw;
        height: 300%;
        content: "";
        background-color: color-white01(1);

        @include breakpoint-pc {
          top: 50vw;
        }
      }
    }

    &_ttl {
//      position: relative;
      position: absolute;
      top: calc( 22.666666666666667vw + 30px );  //(170px / 750px * 100 ) + 30px (丸写真部分は可変、余白の高さはpx固定)
      z-index: 1;
      width: 100%;
      margin: 0 auto;

      @include breakpoint-pc {
        top: calc( 14.814814814814815vw + 60px );  //(240px / 1620px * 100 ) + 60px (丸写真部分は可変、ロゴ＋余白の高さはpx固定)
      }
    }

    &_tategakiHeight {
      @include flexbox;
      height: auto;
      
      @include breakpoint-pc {
        height: 22em;
//        height: 350px;
      }
      @include breakpoint-contentinner {
        height: 20em;
//        height: 325px;
      }
      @include breakpoint-content {
        height: 19em;
//        height: 300px;
      }
    }
    
    &_box {
      &.-b01 {
        @include breakpoint-pc {
          margin-top: -20px;
        }
      }

      &.-b02 {
        @include breakpoint-pc {
          margin-top: -50px;
        }
        @include breakpoint-contentinner {
          margin-top: -100px;
        }
      }

      &.-b03 {
        @include breakpoint-pc {
          transform: translateX(-90px);
        }
        @include breakpoint-contentinner {
          transform: translateX(-120px);
        }
      }

      &.-b04 {
        @include flexbox;
        @include flex-wrap-wrap;
        @include flex-direction-column;
        @include flex-justify-space-between;
        @include flex-align-items-start;
        position: relative;
        
        @include breakpoint-pc {
//          @include flex-direction-row;
          margin-top: -8.928571428571429vw; //50px / 1120px * 100
        }
        @include breakpoint-content {
          margin-top: -100px;
        }
      }
    }
  }

  &_vegetables {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: calc( 22.666666666666667vw + 30px );  //(170px / 750px * 100 ) + 30px (丸写真部分は可変、余白の高さはpx固定)
    
    @include breakpoint-pc {
      height: calc( 14.814814814814815vw + 60px );  //(240px / 1620px * 100 ) + 60px (丸写真部分は可変、ロゴ＋余白の高さはpx固定)
//      height: calc( 33.333333333333333vw + 60px );  //(540px / 1620px * 100 ) + 60px (丸写真部分は可変、ロゴ＋余白の高さはpx固定)
    }
    @include breakpoint-max {
//      height: calc( 33.333333333333333vw + 60px );  //(540px / 1620px * 100 ) + 60px (丸写真部分は可変、ロゴ＋余白の高さはpx固定)
    }
  }


  ///// 動画 /////
  &_movie {
    padding: 40px 0 110px;

    @include breakpoint-pc {
      padding: 120px 0;
    }
    
    &_wrap {
      width: 100%;

      @include breakpoint-pc {
        width: 78.5%;
      }
    }
    
    &_ttl {
      position: relative;
      margin-bottom: 30px;
      
      @include breakpoint-pc {
        position: absolute;
        top: -40px;
        right: 0;
        transform: rotate(90deg) translate(100%, 0);
        transform-origin: top right;
        margin-bottom: 0;
      }
      
      &_line1 {
        
      }
      
      &_line2 {
        padding-left: 60px;
        box-sizing: border-box;
        
        @include breakpoint-pc {
          padding-left: 100px;
        }
      }
    }
    
    &_item {
      position: relative;
      width: 100%;

      @include breakpoint-pc {
        width: 68.18%;
      }
      
      &.-left {
        margin-left: 0;
        margin-right: auto;
      }
      &.-right {
        margin-left: auto;
        margin-right: 0;
      }
      
      .modal-open-btn {
        display: block;
        position: relative;
        
        &:before {
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 2;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          content: "";
          background-image: url("../images/common/icon/movie.png");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 40px auto;

          @include breakpoint-pc {
            background-size: 70px auto;
          }
        }
        &:after {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          content: "";
          background-color: color-bk(0.4);
          opacity: 0;
        }
        
        &:hover {
          &:before {
            background-image: url("../images/common/icon/movie_hover.png");
          }
          &:after {
            opacity: 1;
            
          }
        }
      }
    }
    
    &_cap {
      position: relative;

      @include breakpoint-pc {
        position: absolute;
        @include tategaki('sideways');
      }

      &.-item1 {
        @include breakpoint-pc {
          top: 0;
          right: 0;
          transform: translateX(100%);
        }
      }
      &.-item2 {
        @include breakpoint-pc {
          top: 0;
          left: 0;
          transform: translateX(-100%);
        }
      }
    }
  }
}